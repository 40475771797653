import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const roomGroupUrl = 'pms/roomGroup'

export default {
    namespaced: true,
    state: {
        roomGroups: {
            data: [],
            loading: false
        },
        roomGroup: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getRoomGroups({state, commit}) {
            if (!state.roomGroups.data.length) {
                commit('SET_ROOM_GROUPS_LOADING', true)
                return axiosAdmin.get(`${roomGroupUrl}`)
                    .then((res) => {
                        commit("SET_ROOM_GROUPS_LOADING", false);
                        commit('SET_ROOM_GROUPS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_ROOM_GROUPS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeRoomGroup({commit}, form) {
            return axiosAdmin.post(`${roomGroupUrl}`, form)
                .then((res) => {
                    commit('ADD_ROOM_GROUP', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getRoomGroup({commit}, id) {
            commit('SET_ROOM_GROUP_LOADING', true)
            return axiosAdmin.get(`${roomGroupUrl}/${id}`)
                .then((res) => {
                    commit('SET_ROOM_GROUP_LOADING', false)
                    commit('SET_ROOM_GROUP', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_ROOM_GROUP_LOADING', false)
                    showErrors(err)
                });
        },
        updateRoomGroup({commit}, {id, form}) {
            return axiosAdmin.put(`${roomGroupUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_ROOM_GROUP', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteRoomGroup({commit}, id) {
            return axiosAdmin.delete(`${roomGroupUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_ROOM_GROUP', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ROOM_GROUPS_LOADING: (state, loading) => {
            state.roomGroups.loading = loading
        },
        SET_ROOM_GROUPS: (state, data) => {
            state.roomGroups.data = data
        },
        ADD_ROOM_GROUP: (state, data) => {
            state.roomGroups.data.push(data)
        },
        SET_ROOM_GROUP_LOADING: (state, loading) => {
            state.roomGroup.loading = loading
        },
        SET_ROOM_GROUP: (state, data) => {
            state.roomGroup.data = data
        },
        UPDATE_ROOM_GROUP: (state, {id, data}) => {
            state.roomGroups.data[state.roomGroups.data.findIndex(roomGroup => roomGroup.id === id)] = data
        },
        REMOVE_ROOM_GROUP: (state, id) => {
            state.roomGroups.data = state.roomGroups.data.filter(data => data.id !== id)
        }
    }
}
