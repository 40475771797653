import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const fiscalYearUrl = 'setting/fiscalYear'

export default {
    namespaced: true,
    state: {
        runningYear: {},
        fiscalYears: {
            data: [],
            loading: false
        },
        fiscalYear: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getRunningFiscalYear({commit}) {
            return axiosAdmin.get(`${fiscalYearUrl}/running`)
                .then((res) => {
                    commit('SET_RUNNING_YEAR', res.data.data)
                    return res
                })
                .catch((err) => {
                    showErrors(err)
                });
        },
        getFiscalYears({state, commit}) {
            if (!state.fiscalYears.data.length) {
                commit('SET_FISCAL_YEARS_LOADING', true)
                return axiosAdmin.get(`${fiscalYearUrl}`)
                    .then((res) => {
                        commit("SET_FISCAL_YEARS_LOADING", false);
                        commit('SET_FISCAL_YEARS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_FISCAL_YEARS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeFiscalYear({commit}, form) {
            return axiosAdmin.post(`${fiscalYearUrl}`, form)
                .then((res) => {
                    commit('ADD_FISCAL_YEAR', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getFiscalYear({commit}, id) {
            commit('SET_FISCAL_YEAR_LOADING', true)
            return axiosAdmin.get(`${fiscalYearUrl}/${id}`)
                .then((res) => {
                    commit('SET_FISCAL_YEAR_LOADING', false)
                    commit('SET_FISCAL_YEAR', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_FISCAL_YEAR_LOADING', false)
                    showErrors(err)
                });
        },
        updateFiscalYear({commit}, {id, form}) {
            return axiosAdmin.put(`${fiscalYearUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_FISCAL_YEAR', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteFiscalYear({commit}, id) {
            return axiosAdmin.delete(`${fiscalYearUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_FISCAL_YEAR', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
        updateStatus({commit}, id) {
            return axiosAdmin.put(`${fiscalYearUrl}/${id}/updateStatus`)
                .then((res) => {
                    commit('SET_FISCAL_YEARS', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        }
    },
    mutations: {
        SET_RUNNING_YEAR: (state, data) => {
            state.runningYear = data
        },
        SET_FISCAL_YEARS_LOADING: (state, loading) => {
            state.fiscalYears.loading = loading
        },
        SET_FISCAL_YEARS: (state, data) => {
            state.fiscalYears.data = data
        },
        ADD_FISCAL_YEAR: (state, data) => {
            state.fiscalYears.data.push(data)
        },
        SET_FISCAL_YEAR_LOADING: (state, loading) => {
            state.fiscalYear.loading = loading
        },
        SET_FISCAL_YEAR: (state, data) => {
            state.fiscalYear.data = data
        },
        UPDATE_FISCAL_YEAR: (state, {id, data}) => {
            state.fiscalYears.data[state.fiscalYears.data.findIndex(fiscalYear => fiscalYear.id === id)] = data
        },
        REMOVE_FISCAL_YEAR: (state, id) => {
            state.fiscalYears.data = state.fiscalYears.data.filter(data => data.id !== id)
        }
    }
}
