import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const guestUrl = 'pms/guest'

export default {
    namespaced: true,
    state: {
        guests: {
            data: [],
            loading: false
        },
        guest: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getGuests({state, commit}) {
            if (!state.guests.data.length) {
                commit('SET_GUESTS_LOADING', true)
                return axiosAdmin.get(`${guestUrl}`)
                    .then((res) => {
                        commit("SET_GUESTS_LOADING", false);
                        commit('SET_GUESTS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_GUESTS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeGuest({commit}, form) {
            return axiosAdmin.post(`${guestUrl}`, form)
                .then((res) => {
                    commit('ADD_GUEST', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getGuest({commit}, id) {
            commit('SET_GUEST_LOADING', true)
            return axiosAdmin.get(`${guestUrl}/${id}`)
                .then((res) => {
                    commit('SET_GUEST_LOADING', false)
                    commit('SET_GUEST', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_GUEST_LOADING', false)
                    showErrors(err)
                });
        },
        updateGuest({commit}, {id, form}) {
            return axiosAdmin.put(`${guestUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_GUEST', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteGuest({commit}, id) {
            return axiosAdmin.delete(`${guestUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_GUEST', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_GUESTS_LOADING: (state, loading) => {
            state.guests.loading = loading
        },
        SET_GUESTS: (state, data) => {
            state.guests.data = data
        },
        ADD_GUEST: (state, data) => {
            state.guests.data.push(data)
        },
        SET_GUEST_LOADING: (state, loading) => {
            state.guest.loading = loading
        },
        SET_GUEST: (state, data) => {
            state.guest.data = data
        },
        UPDATE_GUEST: (state, {id, data}) => {
            state.guests.data[state.guests.data.findIndex(guest => guest.id === id)] = data
        },
        REMOVE_GUEST: (state, id) => {
            state.guests.data = state.guests.data.filter(data => data.id !== id)
        }
    }
}
