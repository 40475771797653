<template>
    <label v-if="label" :for="id" class="form-label">{{ label }}</label>
    <input
        :type="inputType"
        :id="id"
        :value="modelValue"
        v-bind:class="[inputClass, { 'is-invalid': error.$error }]"
        :disabled="disabled"
        :placeholder="[placeholder ? placeholder : label]"
    />
    <div v-if="error.$error" class="invalid-feedback">
        {{ error.$errors[0].$message }}
    </div>
</template>

<script>
import { onMounted } from "vue";

export default {
    props: {
        id: {
            type: String,
        },
        inputType: {
            type: String,
            default: "text",
        },
        inputClass: {
            type: String,
            default: "form-control",
        },
        label: {
            type: String,
        },
        placeholder:{
            type:String
        },
        disabled:{
            type:Boolean,
            default:false
        },
        error: {
            type: Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default:{}
        },

        modelValue: {
            type: String,
            required: true,
        },
        todayDate: {
            type: Boolean,
            default: false,
        },
        disableBefore:{
            default:null
        }
    },
    setup(props, { emit }) {

        onMounted(() => {
            let data = document.querySelector("#" + props.id);
            // eslint-disable-next-line no-undef
            let todayDateValue = NepaliFunctions.ConvertDateFormat(
                // eslint-disable-next-line no-undef
                NepaliFunctions.GetCurrentBsDate(),
                "YYYY-MM-DD"
            );
            if (props.todayDate) {
                emit("update:modelValue", todayDateValue);
            }

            data.nepaliDatePicker({
                ndpYear: true,
                ndpMonth: true,
                disableBefore:props.disableBefore,
                onChange: function (e) {
                    emit("update:modelValue", e.bs);
                },
            });
        });
    },
};
</script>
