import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const taxUrl = 'setting/tax'

export default {
    namespaced: true,
    state: {
        taxes: {
            data: [],
            loading: false
        },
        tax: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getTaxCode(){
            return axiosAdmin.get(`${taxUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getTaxes({state, commit}) {
            if (!state.taxes.data.length) {
                commit('SET_TAXES_LOADING', true)
                return axiosAdmin.get(`${taxUrl}`)
                    .then((res) => {
                        commit("SET_TAXES_LOADING", false);
                        commit('SET_TAXES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_TAXES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeTax({commit}, form) {
            return axiosAdmin.post(`${taxUrl}`, form)
                .then((res) => {
                    commit('ADD_TAX', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getTax({commit}, id) {
            commit('SET_TAX_LOADING', true)
            return axiosAdmin.get(`${taxUrl}/${id}`)
                .then((res) => {
                    commit('SET_TAX_LOADING', false)
                    commit('SET_TAX', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_TAX_LOADING', false)
                    showErrors(err)
                });
        },
        updateTax({commit}, {id, form}) {
            return axiosAdmin.put(`${taxUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_TAX', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteTax({commit}, id) {
            return axiosAdmin.delete(`${taxUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_TAX', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_TAXES_LOADING: (state, loading) => {
            state.taxes.loading = loading
        },
        SET_TAXES: (state, data) => {
            state.taxes.data = data
        },
        ADD_TAX: (state, data) => {
            state.taxes.data.push(data)
        },
        SET_TAX_LOADING: (state, loading) => {
            state.tax.loading = loading
        },
        SET_TAX: (state, data) => {
            state.tax.data = data
        },
        UPDATE_TAX: (state, {id, data}) => {
            state.taxes.data[state.taxes.data.findIndex(tax => tax.id === id)] = data
        },
        REMOVE_TAX: (state, id) => {
            state.taxes.data = state.taxes.data.filter(data => data.id !== id)
        }
    }
}
