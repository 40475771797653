import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const saleUrl = 'inventory/sale'

export default {
    namespaced: true,
    state: {
        allSales: {
            data: [],
            loading: false
        },
        clientDueSales: {
            data: [],
            loading: false
        },
        sales: {
            data: [],
            meta: {},
            loading: false
        },
        sale: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getSaleCode() {
            return axiosAdmin.get(`${saleUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getAllSales({commit}, {client_ledger_id}) {
            commit('SET_ALL_SALES_LOADING', true)
            return axiosAdmin.get(`${saleUrl}/all?client_ledger_id=${client_ledger_id}`)
                .then((res) => {
                    commit("SET_ALL_SALES_LOADING", false);
                    commit('SET_ALL_SALES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_SALES_LOADING", false);
                    showErrors(err)
                });
        },
        getClientDueSales({commit}, {
            client_ledger_id,
            sales_payment_status = 'due'
        }) {
            commit('SET_CLIENT_DUE_SALES_LOADING', true)
            return axiosAdmin.get(`${saleUrl}/all?client_ledger_id=${client_ledger_id}&sales_payment_status=${sales_payment_status}`)
                .then((res) => {
                    commit("SET_CLIENT_DUE_SALES_LOADING", false);
                    commit('SET_CLIENT_DUE_SALES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_CLIENT_DUE_SALES_LOADING", false);
                    showErrors(err)
                });
        },
        getSales({commit}, {
            page = 1,
            limit = '',
            search = '',
            client_ledger_id = '',
            from_date = '',
            to_date = ''
        }) {
            commit('SET_SALES_LOADING', true)
            return axiosAdmin.get(`${saleUrl}?page=${page}&limit=${limit}&search=${search}&client_ledger_id=${client_ledger_id}&from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_SALES_LOADING", false);
                    commit('SET_SALES', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_SALES_LOADING", false);
                    showErrors(err)
                });
        },
        storeSale({commit}, form) {
            return axiosAdmin.post(`${saleUrl}`, form)
                .then((res) => {
                    commit('ADD_SALE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getSale({commit}, id) {
            commit('SET_SALE_LOADING', true)
            return axiosAdmin.get(`${saleUrl}/${id}`)
                .then((res) => {
                    commit('SET_SALE_LOADING', false)
                    commit('SET_SALE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_SALE_LOADING', false)
                    showErrors(err)
                });
        },
        deleteSale({commit}, {id, cancel_reason}) {
            return axiosAdmin.delete(`${saleUrl}/${id}?cancel_reason=${cancel_reason}`)
                .then((res) => {
                    commit('REMOVE_SALE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ALL_SALES_LOADING: (state, loading) => {
            state.allSales.loading = loading
        },
        SET_ALL_SALES: (state, data) => {
            state.allSales.data = data
        },
        SET_CLIENT_DUE_SALES_LOADING: (state, loading) => {
            state.clientDueSales.loading = loading
        },
        SET_CLIENT_DUE_SALES: (state, data) => {
            state.clientDueSales.data = data
        },
        SET_SALES_LOADING: (state, loading) => {
            state.sales.loading = loading
        },
        SET_SALES: (state, data) => {
            state.sales.data = data.data
            state.sales.meta = data.meta
        },
        ADD_SALE: (state, data) => {
            state.sales.data.push(data)
        },
        SET_SALE_LOADING: (state, loading) => {
            state.sale.loading = loading
        },
        SET_SALE: (state, data) => {
            state.sale.data = data
        },
        REMOVE_SALE: (state, id) => {
            state.sales.data = state.sales.data.filter(data => data.id !== id)
        }
    }
}
