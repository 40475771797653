import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const purchaseUrl = 'inventory/purchase'

export default {
    namespaced: true,
    state: {
        allPurchases: {
            data: [],
            loading: false
        },
        supplierDuePurchases: {
            data: [],
            loading: false
        },
        purchases: {
            data: [],
            meta: {},
            loading: false
        },
        purchase: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getPurchaseCode() {
            return axiosAdmin.get(`${purchaseUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getAllPurchases({commit}, {
            supplier_ledger_id = '', from_date = '', to_date = '', purchase_payment_status = ''
        }) {
            commit('SET_ALL_PURCHASES_LOADING', true)
            return axiosAdmin.get(`${purchaseUrl}/all?supplier_ledger_id=${supplier_ledger_id}&from_date=${from_date}&to_date=${to_date}&purchase_payment_status=${purchase_payment_status}`)
                .then((res) => {
                    commit("SET_ALL_PURCHASES_LOADING", false);
                    commit('SET_ALL_PURCHASES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_PURCHASES_LOADING", false);
                    showErrors(err)
                });
        },
        getSupplierDuePurchases({commit}, {
            supplier_ledger_id,
            purchase_payment_status = 'due'
        }) {
            commit('SET_SUPPLIER_DUE_PURCHASES_LOADING', true)
            return axiosAdmin.get(`${purchaseUrl}/all?supplier_ledger_id=${supplier_ledger_id}&purchase_payment_status=${purchase_payment_status}`)
                .then((res) => {
                    commit("SET_SUPPLIER_DUE_PURCHASES_LOADING", false);
                    commit('SET_SUPPLIER_DUE_PURCHASES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_SUPPLIER_DUE_PURCHASES_LOADING", false);
                    showErrors(err)
                });
        },
        getPurchases({commit}, {
            page = 1,
            limit = '',
            search = '',
            supplier_ledger_id = '',
            from_date = '',
            to_date = ''
        }) {
            commit('SET_PURCHASES_LOADING', true)
            return axiosAdmin.get(`${purchaseUrl}?page=${page}&limit=${limit}&search=${search}&supplier_ledger_id=${supplier_ledger_id}&from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_PURCHASES_LOADING", false);
                    commit('SET_PURCHASES', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PURCHASES_LOADING", false);
                    showErrors(err)
                });
        },
        storePurchase({commit}, form) {
            return axiosAdmin.post(`${purchaseUrl}`, form)
                .then((res) => {
                    commit('ADD_PURCHASE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getPurchase({commit}, id) {
            commit('SET_PURCHASE_LOADING', true)
            return axiosAdmin.get(`${purchaseUrl}/${id}`)
                .then((res) => {
                    commit('SET_PURCHASE_LOADING', false)
                    commit('SET_PURCHASE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_PURCHASE_LOADING', false)
                    showErrors(err)
                });
        },
        deletePurchase({commit}, {id, cancel_reason}) {
            return axiosAdmin.delete(`${purchaseUrl}/${id}?cancel_reason=${cancel_reason}`)
                .then((res) => {
                    commit('REMOVE_PURCHASE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ALL_PURCHASES_LOADING: (state, loading) => {
            state.allPurchases.loading = loading
        },
        SET_ALL_PURCHASES: (state, data) => {
            state.allPurchases.data = data
        },
        SET_SUPPLIER_DUE_PURCHASES_LOADING: (state, loading) => {
            state.supplierDuePurchases.loading = loading
        },
        SET_SUPPLIER_DUE_PURCHASES: (state, data) => {
            state.supplierDuePurchases.data = data
        },
        SET_PURCHASES_LOADING: (state, loading) => {
            state.purchases.loading = loading
        },
        SET_PURCHASES: (state, data) => {
            state.purchases.data = data.data
            state.purchases.meta = data.meta
        },
        ADD_PURCHASE: (state, data) => {
            state.purchases.data.push(data)
        },
        SET_PURCHASE_LOADING: (state, loading) => {
            state.purchase.loading = loading
        },
        SET_PURCHASE: (state, data) => {
            state.purchase.data = data
        },
        REMOVE_PURCHASE: (state, id) => {
            state.purchases.data = state.purchases.data.filter(data => data.id !== id)
        }
    }
}
