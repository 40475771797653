import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const stockAdjustmentUrl = 'inventory/stockAdjustment'

export default {
    namespaced: true,
    state: {
        stockAdjustments: {
            data: [],
            loading: false
        },
        stockAdjustment: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getStockAdjustments({state, commit}) {
            if (!state.stockAdjustments.data.length) {
                commit('SET_STOCK_ADJUSTMENTS_LOADING', true)
                return axiosAdmin.get(`${stockAdjustmentUrl}`)
                    .then((res) => {
                        commit("SET_STOCK_ADJUSTMENTS_LOADING", false);
                        commit('SET_STOCK_ADJUSTMENTS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_STOCK_ADJUSTMENTS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeStockAdjustment({commit}, form) {
            return axiosAdmin.post(`${stockAdjustmentUrl}`, form)
                .then((res) => {
                    commit('ADD_STOCK_ADJUSTMENT', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getStockAdjustment({commit}, id) {
            commit('SET_STOCK_ADJUSTMENT_LOADING', true)
            return axiosAdmin.get(`${stockAdjustmentUrl}/${id}`)
                .then((res) => {
                    commit('SET_STOCK_ADJUSTMENT_LOADING', false)
                    commit('SET_STOCK_ADJUSTMENT', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_STOCK_ADJUSTMENT_LOADING', false)
                    showErrors(err)
                });
        },
        updateStockAdjustment({commit}, {id, form}) {
            return axiosAdmin.post(`${stockAdjustmentUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_STOCK_ADJUSTMENT', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteStockAdjustment({commit}, id) {
            return axiosAdmin.delete(`${stockAdjustmentUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_STOCK_ADJUSTMENT', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_STOCK_ADJUSTMENTS_LOADING: (state, loading) => {
            state.stockAdjustments.loading = loading
        },
        SET_STOCK_ADJUSTMENTS: (state, data) => {
            state.stockAdjustments.data = data
        },
        ADD_STOCK_ADJUSTMENT: (state, data) => {
            state.stockAdjustments.data.push(data)
        },
        SET_STOCK_ADJUSTMENT_LOADING: (state, loading) => {
            state.stockAdjustment.loading = loading
        },
        SET_STOCK_ADJUSTMENT: (state, data) => {
            state.stockAdjustment.data = data
        },
        UPDATE_STOCK_ADJUSTMENT: (state, {id, data}) => {
            state.stockAdjustments.data[state.stockAdjustments.data.findIndex(stockAdjustment => stockAdjustment.id === id)] = data
        },
        REMOVE_STOCK_ADJUSTMENT: (state, id) => {
            state.stockAdjustments.data = state.stockAdjustments.data.filter(data => data.id !== id)
        }
    }
}
