import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const bookingUrl = 'pms/booking'

export default {
    namespaced: true,
    state: {
        bookings: {
            data: [],
            meta:{},
            loading: false
        },
        booking: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getBookings({commit},{filter}) {
            commit('SET_BOOKINGS_LOADING', true)
            return axiosAdmin.get(`${bookingUrl}?${new URLSearchParams(filter).toString()}`)
                .then((res) => {
                    commit("SET_BOOKINGS_LOADING", false);
                    commit('SET_BOOKINGS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_BOOKINGS_LOADING", false);
                    showErrors(err)
                });
        },
        storeBooking({commit}, form) {
            return axiosAdmin.post(`${bookingUrl}`, form)
                .then((res) => {
                    commit('ADD_BOOKING', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getBooking({commit}, id) {
            commit('SET_BOOKING_LOADING', true)
            return axiosAdmin.get(`${bookingUrl}/${id}`)
                .then((res) => {
                    commit('SET_BOOKING_LOADING', false)
                    commit('SET_BOOKING', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_BOOKING_LOADING', false)
                    showErrors(err)
                });
        },
        updateBooking({commit}, {id, form}) {
            return axiosAdmin.put(`${bookingUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_BOOKING', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        updateBookingStatus({commit}, {id, status}) {
            return axiosAdmin.put(`${bookingUrl}/${id}/updateStatus`, status)
                .then((res) => {
                    commit('REMOVE_BOOKING', id)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteBooking({commit}, id) {
            return axiosAdmin.delete(`${bookingUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_BOOKING', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_BOOKINGS_LOADING: (state, loading) => {
            state.bookings.loading = loading
        },
        SET_BOOKINGS: (state, data) => {
            state.bookings.data = data.data
            state.bookings.meta = data.meta
        },
        ADD_BOOKING: (state, data) => {
            state.bookings.data.push(data)
        },
        SET_BOOKING_LOADING: (state, loading) => {
            state.booking.loading = loading
        },
        SET_BOOKING: (state, data) => {
            state.booking.data = data
        },
        UPDATE_BOOKING: (state, {id, data}) => {
            state.bookings.data[state.bookings.data.findIndex(booking => booking.id === id)] = data
        },
        REMOVE_BOOKING: (state, id) => {
            state.bookings.data = state.bookings.data.filter(data => data.id !== id)
        }
    }
}
