import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const roomUrl = 'pms/room'

export default {
    namespaced: true,
    state: {
        rooms: {
            data: [],
            loading: false
        },
        room: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getRooms({state, commit}) {
            if (!state.rooms.data.length) {
                commit('SET_ROOMS_LOADING', true)
                return axiosAdmin.get(`${roomUrl}`)
                    .then((res) => {
                        commit("SET_ROOMS_LOADING", false);
                        commit('SET_ROOMS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_ROOMS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeRoom({commit}, form) {
            return axiosAdmin.post(`${roomUrl}`, form)
                .then((res) => {
                    commit('ADD_ROOM', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getRoom({commit}, id) {
            commit('SET_ROOM_LOADING', true)
            return axiosAdmin.get(`${roomUrl}/${id}`)
                .then((res) => {
                    commit('SET_ROOM_LOADING', false)
                    commit('SET_ROOM', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_ROOM_LOADING', false)
                    showErrors(err)
                });
        },
        updateRoom({commit}, {id, form}) {
            return axiosAdmin.put(`${roomUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_ROOM', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteRoom({commit}, id) {
            return axiosAdmin.delete(`${roomUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_ROOM', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ROOMS_LOADING: (state, loading) => {
            state.rooms.loading = loading
        },
        SET_ROOMS: (state, data) => {
            state.rooms.data = data
        },
        ADD_ROOM: (state, data) => {
            state.rooms.data.push(data)
        },
        SET_ROOM_LOADING: (state, loading) => {
            state.room.loading = loading
        },
        SET_ROOM: (state, data) => {
            state.room.data = data
        },
        UPDATE_ROOM: (state, {id, data}) => {
            state.rooms.data[state.rooms.data.findIndex(room => room.id === id)] = data
        },
        REMOVE_ROOM: (state, id) => {
            state.rooms.data = state.rooms.data.filter(data => data.id !== id)
        }
    }
}
