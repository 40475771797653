import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const receiptVoucherUrl = 'account/receiptVoucher'

export default {
    namespaced: true,
    state: {
        receiptVouchers: {
            data: [],
            loading: false
        },
        receiptVoucher: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getReceiptVoucherCode(){
            return axiosAdmin.get(`${receiptVoucherUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getReceiptVouchers({state, commit}) {
            if (!state.receiptVouchers.data.length) {
                commit('SET_RECEIPT_VOUCHERS_LOADING', true)
                return axiosAdmin.get(`${receiptVoucherUrl}`)
                    .then((res) => {
                        commit("SET_RECEIPT_VOUCHERS_LOADING", false);
                        commit('SET_RECEIPT_VOUCHERS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_RECEIPT_VOUCHERS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeReceiptVoucher({commit}, form) {
            return axiosAdmin.post(`${receiptVoucherUrl}`, form)
                .then((res) => {
                    commit('ADD_RECEIPT_VOUCHER', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getReceiptVoucher({commit}, id) {
            commit('SET_RECEIPT_VOUCHER_LOADING', true)
            return axiosAdmin.get(`${receiptVoucherUrl}/${id}`)
                .then((res) => {
                    commit('SET_RECEIPT_VOUCHER_LOADING', false)
                    commit('SET_RECEIPT_VOUCHER', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_RECEIPT_VOUCHER_LOADING', false)
                    showErrors(err)
                });
        },
        updateReceiptVoucher({commit}, {id, form}) {
            return axiosAdmin.put(`${receiptVoucherUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_RECEIPT_VOUCHER', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteReceiptVoucher({commit}, {id,cancelled_reason}) {
            return axiosAdmin.delete(`${receiptVoucherUrl}/${id}?cancelled_reason=${cancelled_reason}`)
                .then((res) => {
                    commit('REMOVE_RECEIPT_VOUCHER', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_RECEIPT_VOUCHERS_LOADING: (state, loading) => {
            state.receiptVouchers.loading = loading
        },
        SET_RECEIPT_VOUCHERS: (state, data) => {
            state.receiptVouchers.data = data
        },
        ADD_RECEIPT_VOUCHER: (state, data) => {
            state.receiptVouchers.data.push(data)
        },
        SET_RECEIPT_VOUCHER_LOADING: (state, loading) => {
            state.receiptVoucher.loading = loading
        },
        SET_RECEIPT_VOUCHER: (state, data) => {
            state.receiptVoucher.data = data
        },
        UPDATE_RECEIPT_VOUCHER: (state, {id, data}) => {
            state.receiptVouchers.data[state.receiptVouchers.data.findIndex(receiptVoucher => receiptVoucher.id === id)] = data
        },
        REMOVE_RECEIPT_VOUCHER: (state, id) => {
            state.receiptVouchers.data = state.receiptVouchers.data.filter(data => data.id !== id)
        }
    }
}
