import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const brandUrl = 'inventory/brand'

export default {
    namespaced: true,
    state: {
        brands: {
            data: [],
            loading: false
        },
        brand: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getBrands({state, commit}) {
            if (!state.brands.data.length) {
                commit('SET_BRANDS_LOADING', true)
                return axiosAdmin.get(`${brandUrl}`)
                    .then((res) => {
                        commit("SET_BRANDS_LOADING", false);
                        commit('SET_BRANDS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_BRANDS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeBrand({commit}, form) {
            return axiosAdmin.post(`${brandUrl}`, form)
                .then((res) => {
                    commit('ADD_BRAND', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getBrand({commit}, id) {
            commit('SET_BRAND_LOADING', true)
            return axiosAdmin.get(`${brandUrl}/${id}`)
                .then((res) => {
                    commit('SET_BRAND_LOADING', false)
                    commit('SET_BRAND', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_BRAND_LOADING', false)
                    showErrors(err)
                });
        },
        updateBrand({commit}, {id, form}) {
            return axiosAdmin.post(`${brandUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_BRAND', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteBrand({commit}, id) {
            return axiosAdmin.delete(`${brandUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_BRAND', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_BRANDS_LOADING: (state, loading) => {
            state.brands.loading = loading
        },
        SET_BRANDS: (state, data) => {
            state.brands.data = data
        },
        ADD_BRAND: (state, data) => {
            state.brands.data.push(data)
        },
        SET_BRAND_LOADING: (state, loading) => {
            state.brand.loading = loading
        },
        SET_BRAND: (state, data) => {
            state.brand.data = data
        },
        UPDATE_BRAND: (state, {id, data}) => {
            state.brands.data[state.brands.data.findIndex(brand => brand.id === id)] = data
        },
        REMOVE_BRAND: (state, id) => {
            state.brands.data = state.brands.data.filter(data => data.id !== id)
        }
    }
}
