import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const areaUrl = 'restaurant/area'

export default {
    namespaced: true,
    state: {
        areas: {
            data: [],
            loading: false
        },
        area: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getAreas({commit}) {
            commit('SET_AREAS_LOADING', true)
            return axiosAdmin.get(`${areaUrl}`)
                .then((res) => {
                    commit("SET_AREAS_LOADING", false);
                    commit('SET_AREAS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_AREAS_LOADING", false);
                    showErrors(err)
                });
        },
        storeArea({commit}, form) {
            return axiosAdmin.post(`${areaUrl}`, form)
                .then((res) => {
                    commit('ADD_AREA', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getArea({commit}, id) {
            commit('SET_AREA_LOADING', true)
            return axiosAdmin.get(`${areaUrl}/${id}`)
                .then((res) => {
                    commit('SET_AREA_LOADING', false)
                    commit('SET_AREA', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_AREA_LOADING', false)
                    showErrors(err)
                });
        },
        updateArea({commit}, {id, form}) {
            return axiosAdmin.put(`${areaUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_AREA', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteArea({commit}, id) {
            return axiosAdmin.delete(`${areaUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_AREA', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_AREAS_LOADING: (state, loading) => {
            state.areas.loading = loading
        },
        SET_AREAS: (state, data) => {
            state.areas.data = data
        },
        ADD_AREA: (state, data) => {
            state.areas.data.push(data)
        },
        SET_AREA_LOADING: (state, loading) => {
            state.area.loading = loading
        },
        SET_AREA: (state, data) => {
            state.area.data = data
        },
        UPDATE_AREA: (state, {id, data}) => {
            state.areas.data[state.areas.data.findIndex(area => area.id === id)] = data
        },
        REMOVE_AREA: (state, id) => {
            state.areas.data = state.areas.data.filter(data => data.id !== id)
        }
    }
}
