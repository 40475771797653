import {createRouter, createWebHashHistory, RouterView} from "vue-router";
import store from "@/store";
import NProgress from "nprogress";

NProgress.configure({showSpinner: false});
NProgress.configure({trickleRate: 0.02, trickleSpeed: 800});

const routes = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        name: "Login",
        meta: {isGuest: true},
        component: () => import('@/views/Login.vue'),
    },
    {
        path: "/admin",
        component: () => import("@/layouts/AdminLayout"),
        redirect: "admin/dashboard",
        meta: {requiresAuth: true},
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                component: () => import("@/views/Dashboard"),
            },
            {
                path: "profile",
                name: "Profile",
                component: () => import("@/views/profile/Index"),
            },
            {
                path: "user",
                name: "UserIndex",
                component: () => import("@/views/userManagement/user/Index.vue"),
            },
            {
                path: "role",
                name: "RoleIndex",
                component: () => import("@/views/userManagement/role/Index.vue"),
            },
            {
                path: "setting",
                name: "Setting",
                component: RouterView,
                children: [
                    {
                        path: "fiscal-year",
                        name: "FiscalYearIndex",
                        component: () => import("@/views/setting/fiscalYear/Index.vue"),
                    },
                    {
                        path: "company-setting",
                        name: "CompanySettingIndex",
                        component: () => import("@/views/setting/CompanySetting.vue"),
                    },
                    {
                        path: "tax",
                        name: "TaxIndex",
                        component: () => import("@/views/setting/tax/Index.vue"),
                    },
                    {
                        path: 'account-setting',
                        name: 'AccountSettingIndex',
                        component: () => import('@/views/setting/AccountSetting.vue')
                    },
                    {
                        path: 'bank',
                        name: 'BankIndex',
                        component: () => import('@/views/setting/bank/Index.vue')
                    }
                ],
            },
            {
                path: "account",
                name: "Account",
                component: RouterView,
                children: [
                    {
                        path: "ledger",
                        name: "LedgerIndex",
                        component: () => import("@/views/account/ledger/Index.vue"),
                    },
                    {
                        path: "ledger-group",
                        name: "LedgerGroupIndex",
                        component: () => import("@/views/account/ledgerGroup/Index.vue"),
                    },
                    {
                        path: 'bank-account',
                        name: "BankAccountIndex",
                        component: () => import("@/views/account/bankAccount/Index.vue")
                    },
                    {
                        path: 'account-opening-balance',
                        name: "AccountOpeningBalanceIndex",
                        component: () => import("@/views/account/AccountOpeningBalance/Index.vue")
                    },
                    {
                        path: "journal-voucher",
                        name: "JournalVoucherIndex",
                        component: () => import("@/views/account/journalVoucher/Index.vue"),
                    },
                    {
                        path: "payment-voucher",
                        name: "PaymentVoucherIndex",
                        component: () => import("@/views/account/paymentVoucher/Index.vue"),
                    },
                    {
                        path: "receipt-voucher",
                        name: "ReceiptVoucherIndex",
                        component: () => import("@/views/account/receiptVoucher/Index.vue"),
                    },
                    {
                        path: 'report',
                        component: RouterView,
                        children: [
                            {
                                path: "general-ledger",
                                name: "GeneralLedgerReport",
                                component: () => import("@/views/account/report/GeneralLedger.vue")
                            },
                            {
                                path: "balance-sheet",
                                name: "BalanceSheet",
                                component: () => import("@/views/account/report/BalanceSheet.vue")
                            },
                            {
                                path: "profit-loss-statement",
                                name: "ProfitLossStatement",
                                component: () => import("@/views/account/report/ProfitLossStatement.vue")
                            },
                            {
                                path: "day-book",
                                name: "DayBook",
                                component: () => import("@/views/account/report/DayBook.vue")
                            },
                            {
                                path: "trial-balance",
                                name: "TrialBalance",
                                component: () => import("@/views/account/report/TrialBalance.vue")
                            }
                        ]
                    }
                ],
            },
            {
                path: "crm",
                name: "Crm",
                component: RouterView,
                children: [
                    {
                        path: "client-group",
                        name: "ClientGroupIndex",
                        component: () => import("@/views/crm/client_group/Index.vue"),
                    },
                    {
                        path: "company",
                        name: "CompanyIndex",
                        component: () => import("@/views/crm/company/Index.vue"),
                    },
                    {
                        path: "client",
                        name: "ClientIndex",
                        component: () => import("@/views/crm/client/Index.vue"),
                    },
                    {
                        path: 'client-due-report',
                        name: 'ClientDueReport',
                        component: () => import('@/views/crm/client/ClientDueReport.vue')
                    },
                    {
                        path: "supplier",
                        name: "SupplierIndex",
                        component: () => import("@/views/crm/supplier/Index.vue"),
                    },
                ],
            },
            {
                path: "inventory",
                name: "Inventory",
                component: RouterView,
                children: [
                    {
                        path: "unit",
                        name: "UnitIndex",
                        component: () => import('@/views/inventory/unit/Index.vue')
                    },
                    {
                        path: "warehouse",
                        name: "WarehouseIndex",
                        component: () => import('@/views/inventory/warehouse/Index.vue')
                    },
                    {
                        path: "brand",
                        name: "BrandIndex",
                        component: () => import('@/views/inventory/brand/Index.vue')
                    },
                    {
                        path: "product-category",
                        name: "ProductCategoryIndex",
                        component: () => import('@/views/inventory/product_category/Index.vue')
                    },
                    {
                        path: 'product',
                        name: 'ProductIndex',
                        component: () => import('@/views/inventory/product/Index.vue')
                    },
                    {
                        path: 'out-of-stock-products',
                        name: 'OutOfStockProducts',
                        component: () => import('@/views/inventory/product/stock_out_products.vue')
                    },
                    {
                        path: 'purchase',
                        name: 'PurchaseIndex',
                        component: () => import('@/views/inventory/purchase/Index.vue')
                    },
                    {
                        path: 'purchase-return',
                        name: 'PurchaseReturnIndex',
                        component: () => import('@/views/inventory/purchase_return/Index.vue')
                    },
                    {
                        path: 'supplier-payment',
                        name: 'PaymentRecordIndex',
                        component: () => import('@/views/inventory/payment_record/Index.vue')
                    },
                    {
                        path: 'sales',
                        name: 'SalesIndex',
                        component: () => import('@/views/inventory/sale/Index.vue')
                    },
                    {
                        path: 'sales-return',
                        name: 'SalesReturnIndex',
                        component: () => import('@/views/inventory/sales_return/Index.vue')
                    },
                    {
                        path: 'quotation',
                        name: 'QuotationIndex',
                        component: () => import('@/views/inventory/quotation/Index.vue')
                    },
                    {
                        path: 'client-payment',
                        name: 'ReceiptRecordIndex',
                        component: () => import('@/views/inventory/receipt_record/Index.vue')
                    },
                    {
                        path: 'product-opening',
                        name: 'ProductOpeningIndex',
                        component: () => import('@/views/inventory/productOpening/Index.vue')
                    },
                    {
                        path: 'stock-adjustment',
                        name: 'StockAdjustmentIndex',
                        component: () => import('@/views/inventory/stock_adjustment/Index.vue')
                    },
                    {
                        path: 'report',
                        component: RouterView,
                        children: [
                            {
                                path: "stock-summary",
                                name: "CategoryStockSummary",
                                component: () => import("@/views/inventory/report/CategoryStockSummary.vue")
                            },
                            {
                                path: "stock-summary/:product_category_id",
                                name: "ProductStockSummary",
                                component: () => import("@/views/inventory/report/ProductStockSummary.vue")
                            },
                            {
                                path: "product-ledger",
                                name: "ProductLedgerReport",
                                component: () => import("@/views/inventory/report/ProductLedger.vue")
                            },
                            {
                                path: 'purchase',
                                component: RouterView,
                                children: [
                                    {
                                        path: 'product-wise',
                                        name: 'ProductWisePurchaseReport',
                                        component: () => import("@/views/inventory/report/purchase/ProductWisePurchaseReport.vue")
                                    },
                                    {
                                        path: 'summary',
                                        name: 'PurchaseSummaryReport',
                                        component: () => import("@/views/inventory/report/purchase/PurchaseSummaryReport.vue")
                                    }
                                ]
                            },
                            {
                                path: 'sales',
                                component: RouterView,
                                children: [
                                    {
                                        path: 'product-wise',
                                        name: 'ProductWiseSalesReport',
                                        component: () => import("@/views/inventory/report/sales/ProductWiseSalesReport.vue")
                                    },
                                    {
                                        path: 'summary',
                                        name: 'SalesSummaryReport',
                                        component: () => import("@/views/inventory/report/sales/SalesSummaryReport.vue")
                                    }
                                ]
                            }
                        ]
                    }
                ],
            },
            {
                path: "hr",
                name: "hr",
                component: RouterView,
                children: [
                    {
                        path: "department",
                        name: "DepartmentIndex",
                        component: () => import('@/views/humanResource/department/Index.vue')
                    },
                    {
                        path: "designation",
                        name: "DesignationIndex",
                        component: () => import('@/views/humanResource/designation/Index.vue')
                    },
                    {
                        path: "employee",
                        name: "EmployeeIndex",
                        component: () => import('@/views/humanResource/Employee/Index.vue')
                    },
                    {
                        path: "leave-type",
                        name: "LeaveTypeIndex",
                        component: () => import('@/views/humanResource/leaveType/Index.vue')
                    },
                    {
                        path: "leave",
                        name: "LeaveIndex",
                        component: () => import('@/views/humanResource/leave/Index.vue')
                    },
                    {
                        path: "employee-attendance",
                        name: "EmployeeAttendanceIndex",
                        component: () => import('@/views/humanResource/employeeAttendance/Index.vue')
                    }
                ],
            },
            {
                path: "payroll",
                name: "Payroll",
                component: RouterView,
                children: [
                    {
                        path: "pay-head",
                        name: "PayHeadIndex",
                        component: () => import('@/views/humanResource/pay_head/Index.vue')
                    },
                    {
                        path: "employee-salary",
                        name: "EmployeeSalaryIndex",
                        component: () => import('@/views/humanResource/employee_salary/Index.vue')
                    },
                    {
                        path: "advance-salary",
                        name: "AdvanceSalaryIndex",
                        component: () => import('@/views/humanResource/advance_salary/Index.vue')
                    },
                    {
                        path: "payable-charge",
                        name: "PayableChargeIndex",
                        component: () => import('@/views/humanResource/payable_charge/Index.vue')
                    },
                    {
                        path: "salary-payment",
                        name: "SalaryPaymentIndex",
                        component: () => import('@/views/humanResource/salary_payment/Index.vue')
                    },
                    {
                        path: 'report',
                        component: RouterView,
                        children: [
                            {
                                path: "salary-ledger",
                                name: 'SalaryLedgerReport',
                                component: () => import('@/views/humanResource/report/SalaryLedgerReport.vue')
                            },
                            {
                                path: "payslip",
                                name: 'PayslipReport',
                                component: () => import('@/views/humanResource/report/PayslipReport.vue')
                            },
                            {
                                path: "payable-charge",
                                name: 'PayableChargeReport',
                                component: () => import('@/views/humanResource/report/PayableChargeReport.vue')
                            }
                        ]
                    }
                ],
            },
            {
                path: "sms",
                name: "Sms",
                component: RouterView,
                children: [
                    {
                        path: "sms-template",
                        name: "SmsTemplateIndex",
                        component: () => import('@/views/sms/sms_template/Index.vue')
                    },
                    {
                        path: 'send-sms',
                        name: 'SendSmsIndex',
                        component: () => import('@/views/sms/sendMessage.vue')
                    },
                    {
                        path: 'sms-report',
                        name: 'SmsReport',
                        component: () => import('@/views/sms/SmsReport.vue')
                    }
                ],
            },
        ],
    },
    {
        path: "/admin/inventory/pos",
        meta: {requiresAuth: true},
        name: 'PosIndex',
        component: () => import('@/views/inventory/pos/Index.vue')
    },
    //restaurant routes
    {
        path: "/admin/restaurant",
        component: () => import("@/layouts/RestaurantLayout.vue"),
        meta: {requiresAuth: true},
        children: [
            {
                path: "dashboard",
                name: "RestaurantDashboard",
                component: () => import("@/views/restaurant/Dashboard.vue"),
            },
            {
                path: "menu",
                name: "RestaurantMenu",
                component: () => import("@/views/restaurant/RestaurantMenu.vue"),
            },
            {
                path: "area-tables",
                name: "AreaTables",
                component: () => import("@/views/restaurant/AreaTables.vue"),
            },
            {
                path: "order-list",
                name: "RestaurantOrderList",
                component: () => import("@/views/restaurant/order/OrderList.vue")
            },
            {
                path: 'setting',
                component: RouterView,
                children: [
                    {
                        path: "area",
                        name: "AreaIndex",
                        component: () => import('@/views/restaurant/area/Index.vue')
                    },
                    {
                        path: "restaurant-table",
                        name: "RestaurantTableIndex",
                        component: () => import('@/views/restaurant/restaurantTable/Index.vue')
                    },
                    {
                        path: "food-item",
                        name: "FoodItemIndex",
                        component: () => import('@/views/restaurant/foodItem/Index.vue')
                    },
                    {
                        path: "menu-category",
                        name: "MenuCategoryIndex",
                        component: () => import('@/views/restaurant/menuCategory/Index.vue')
                    },
                    {
                        path: "menu-item",
                        name: "MenuItemIndex",
                        component: () => import('@/views/restaurant/menuItem/Index.vue')
                    }
                ]
            },
            {
                path: "pms",
                name: "Pms",
                component: RouterView,
                children: [
                    {
                        path: "booking-type",
                        name: "BookingTypeIndex",
                        component: () => import("@/views/pms/booking_type/Index.vue"),
                    },
                    {
                        path: "room-amenity",
                        name: "RoomAmenityIndex",
                        component: () => import("@/views/pms/room_amenity/Index.vue"),
                    },
                    {
                        path: "room-group",
                        name: "RoomGroupIndex",
                        component: () => import("@/views/pms/room_group/Index.vue"),
                    },
                    {
                        path: "room-category",
                        name: "RoomCategoryIndex",
                        component: () => import("@/views/pms/room_category/Index.vue"),
                    },

                    {
                        path: "booking-source",
                        name: "BookingSourceIndex",
                        component: () => import("@/views/pms/booking_source/Index.vue"),
                    },
                ],
            },
        ],
    },
    //pms routes
    {
        path: "/admin/pms",
        component: () => import("@/layouts/PmsLayout.vue"),
        meta: {requiresAuth: true},
        children: [
            {
                path: "dashboard",
                name: "PmsDashboard",
                component: () => import("@/views/pms/Dashboard.vue"),
            },
            {
                path: "booking-type",
                name: "BookingTypeIndex",
                component: () => import("@/views/pms/booking_type/Index.vue"),
            },
            {
                path: "room-amenity",
                name: "RoomAmenityIndex",
                component: () => import("@/views/pms/room_amenity/Index.vue"),
            },
            {
                path: "room-group",
                name: "RoomGroupIndex",
                component: () => import("@/views/pms/room_group/Index.vue"),
            },
            {
                path: "room-category",
                name: "RoomCategoryIndex",
                component: () => import("@/views/pms/room_category/Index.vue"),
            },
            {
                path: "room",
                name: "RoomIndex",
                component: () => import("@/views/pms/room/Index.vue"),
            },
            {
                path: "guest",
                name: "GuestIndex",
                component: () => import("@/views/pms/guest/Index.vue"),
            },
            {
                path: "booking-source",
                name: "BookingSourceIndex",
                component: () => import("@/views/pms/booking_source/Index.vue"),
            },
            {
                path: "booking",
                name: "BookingIndex",
                component: () => import("@/views/pms/booking/Index.vue"),
            },
            {
                path: "checkin-list",
                name: "CheckinBookingList",
                component: () => import("@/views/pms/booking/CheckinBookingList.vue"),
            },
            {
                path: "checkout-list",
                name: "CheckoutBookingList",
                component: () => import("@/views/pms/booking/CheckoutBookingList.vue"),
            },
        ],
    },
    {
        path: "/admin/restaurant/pos",
        meta: {requiresAuth: true},
        name: 'RestaurantPosIndex',
        component: () => import('@/views/restaurant/pos/Index.vue')
    },
    {
        path: "/:pathMatch(.*)*",
        name:'404Page',
        component: () => import("@/views/404.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    if (to.meta.requiresAuth && !store.state.user.access_token) {
        next({name: "Login"});
    } else if (store.state.user.access_token && to.meta.isGuest) {
        next({name: "Dashboard"});
    } else if (window.location.protocol !== 'https:' && process.env.NODE_ENV === 'production') {
        window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`)
    } else {
        next();
    }
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
