import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const receiptRecordUrl = 'inventory/receiptRecord'

export default {
    namespaced: true,
    state: {
        allReceiptRecords: {
            data: [],
            loading: false
        },
        receiptRecords: {
            data: [],
            meta: {},
            loading: false
        },
        receiptRecord: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getReceiptRecordCode() {
            return axiosAdmin.get(`${receiptRecordUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getAllReceiptRecords({commit}, {from_date = '', to_date = '', client_ledger_id = ''}) {
            commit('SET_ALL_RECEIPT_RECORDS_LOADING', true)
            return axiosAdmin.get(`${receiptRecordUrl}?from_date=${from_date}&to_date=${to_date}&client_ledger_id=${client_ledger_id}`)
                .then((res) => {
                    commit("SET_ALL_RECEIPT_RECORDS_LOADING", false);
                    commit('SET_ALL_RECEIPT_RECORDS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_RECEIPT_RECORDS_LOADING", false);
                    showErrors(err)
                });
        },
        getReceiptRecords({commit}, {
            page = 1,
            limit = '',
            search = '',
            from_date = '',
            to_date = '',
            client_ledger_id = ''
        }) {
            commit('SET_RECEIPT_RECORDS_LOADING', true)
            return axiosAdmin.get(`${receiptRecordUrl}?page=${page}&limit=${limit}&search=${search}&from_date=${from_date}&to_date=${to_date}&client_ledger_id=${client_ledger_id}`)
                .then((res) => {
                    commit("SET_RECEIPT_RECORDS_LOADING", false);
                    commit('SET_RECEIPT_RECORDS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_RECEIPT_RECORDS_LOADING", false);
                    showErrors(err)
                });
        },
        storeReceiptRecord({commit}, form) {
            return axiosAdmin.post(`${receiptRecordUrl}`, form)
                .then((res) => {
                    commit('ADD_RECEIPT_RECORDS', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getReceiptRecord({commit}, id) {
            commit('RECEIPT_RECORD_LOADING', true)
            return axiosAdmin.get(`${receiptRecordUrl}/${id}`)
                .then((res) => {
                    commit('RECEIPT_RECORD_LOADING', false)
                    commit('SET_RECEIPT_RECORD', res.data.data)
                    return res
                }).catch((err) => {
                    commit('RECEIPT_RECORD_LOADING', false)
                    showErrors(err)
                });
        },
        deleteReceiptRecord({commit}, {id, cancel_reason}) {
            return axiosAdmin.delete(`${receiptRecordUrl}/${id}?cancel_reason=${cancel_reason}`)
                .then((res) => {
                    commit('REMOVE_RECEIPT_RECORD', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ALL_RECEIPT_RECORDS_LOADING: (state, loading) => {
            state.allReceiptRecords.loading = loading
        },
        SET_ALL_RECEIPT_RECORDS: (state, data) => {
            state.allReceiptRecords.data = data
        },
        SET_RECEIPT_RECORDS_LOADING: (state, loading) => {
            state.receiptRecords.loading = loading
        },
        SET_RECEIPT_RECORDS: (state, data) => {
            state.receiptRecords.data = data.data
            state.receiptRecords.meta = data.meta
        },
        RECEIPT_RECORD_LOADING: (state, loading) => {
            state.receiptRecord.loading = loading
        },
        SET_RECEIPT_RECORD: (state, data) => {
            state.receiptRecord.data = data
        },
        ADD_RECEIPT_RECORDS: (state, data) => {
            data.forEach(value => {
                state.receiptRecords.data.push(value)
            })
        },
        REMOVE_RECEIPT_RECORD: (state, id) => {
            state.receiptRecords.data = state.receiptRecords.data.filter(data => data.id !== id)
        }
    }
}
