import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

export default {
    namespaced: true,
    state: {
        creditBalance: {},
        sentMessages: {
            data: [],
            loading: false
        }
    },
    getters: {},
    actions: {
        getCreditBalance({commit}) {
            return axiosAdmin.get(`sms/credit-balance`)
                .then((res) => {
                    commit('SET_CREDIT_BALANCE', res.data)
                    return res
                }).catch((err) => {
                    showErrors(err)
                    throw err;
                });
        },
        getSentMessages({commit}, {from_date = '', to_date = ''}) {
            commit('SET_SENT_MESSAGES_LOADING', true)
            return axiosAdmin.get(`sms/sentMessages?from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_SENT_MESSAGES_LOADING", false);
                    commit('SET_SENT_MESSAGES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_SENT_MESSAGES_LOADING", false);
                    showErrors(err)
                });
        },
        // eslint-disable-next-line no-unused-vars
        storeSingleSms({commit}, form) {
            return axiosAdmin.post(`sms/send-single-sms`, form)
                .then((res) => {
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        // eslint-disable-next-line no-unused-vars
        storeGroupSms({commit}, form) {
            return axiosAdmin.post(`sms/send-group-sms`, form)
                .then((res) => {
                    return res
                }).catch((err) => {
                    throw err
                })
        }
    },
    mutations: {
        SET_CREDIT_BALANCE: (state, data) => {
            state.creditBalance = data
        },
        SET_SENT_MESSAGES_LOADING: (state, loading) => {
            state.sentMessages.loading = loading
        },
        SET_SENT_MESSAGES: (state, data) => {
            state.sentMessages.data = data
        }
    }
}
