import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

export default {
    namespaced: true,
    state: {
        roles: {
            data: [],
            loading: false
        },
        role: {
            data: {},
            loading: false
        },
        permissions: {
            data:{},
            loading:false
        }
    },
    getters: {},
    actions: {
        getPermissions({state, commit}) {
            if (!state.permissions.data.length) {
                return axiosAdmin.get(`permission`)
                    .then(res => {
                        commit('SET_PERMISSIONS', res.data)
                        return res
                    }).catch((err) => {
                        showErrors(err)
                    });
            }
        },
        getRoles({state, commit}) {
            if (!state.roles.data.length) {
                commit('SET_ROLES_LOADING', true)
                return axiosAdmin.get(`role`)
                    .then((res) => {
                        commit("SET_ROLES_LOADING", false);
                        commit('SET_ROLES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_ROLES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeRole({commit}, form) {
            return axiosAdmin.post(`role`, form)
                .then((res) => {
                    commit('ADD_ROLE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getRole({commit}, id) {
            commit('SET_ROLE_LOADING', true)
            return axiosAdmin.get(`role/${id}`)
                .then((res) => {
                    commit('SET_ROLE_LOADING', false)
                    commit('SET_ROLE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_ROLE_LOADING', false)
                    showErrors(err)
                });
        },
        updateRole({commit}, {id, form}) {
            return axiosAdmin.put(`role/${id}`, form)
                .then((res) => {
                    commit('UPDATE_ROLE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteRole({commit}, id) {
            return axiosAdmin.delete(`role/${id}`)
                .then((res) => {
                    commit('REMOVE_ROLE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_PERMISSIONS: (state, data) => {
            state.permissions.data = data.data
        },
        SET_ROLES_LOADING: (state, loading) => {
            state.roles.loading = loading
        },
        SET_ROLES: (state, data) => {
            state.roles.data = data
        },
        ADD_ROLE: (state, data) => {
            state.roles.data.push(data)
        },
        SET_ROLE_LOADING: (state, loading) => {
            state.role.loading = loading
        },
        SET_ROLE: (state, data) => {
            state.role.data = data
        },
        UPDATE_ROLE: (state, {id, data}) => {
            state.roles.data[state.roles.data.findIndex(role => role.id === id)] = data
        },
        REMOVE_ROLE: (state, id) => {
            state.roles.data = state.roles.data.filter(data => data.id !== id)
        }
    }
}
