import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const clientUrl = 'crm/client'

export default {
    namespaced: true,
    state: {
        clients: {
            data: [],
            loading: false
        },
        client: {
            data: {},
            loading: false
        },
        clientDueReports:{
            data:[],
            loading:false
        }
    },
    getters: {},
    actions: {
        getClientCode() {
            return axiosAdmin.get(`${clientUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getClients({state, commit}) {
            if (!state.clients.data.length) {
                commit('SET_CLIENTS_LOADING', true)
                return axiosAdmin.get(`${clientUrl}`)
                    .then((res) => {
                        commit("SET_CLIENTS_LOADING", false);
                        commit('SET_CLIENTS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_CLIENTS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeClient({commit}, form) {
            return axiosAdmin.post(`${clientUrl}`, form)
                .then((res) => {
                    commit('ADD_CLIENT', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },

        importClient({dispatch}, data) {
            return axiosAdmin.post(`${clientUrl}/excel/import`, data)
                .then((res) => {
                    dispatch("getClients")
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getClient({commit}, id) {
            commit('SET_CLIENT_LOADING', true)
            return axiosAdmin.get(`${clientUrl}/${id}`)
                .then((res) => {
                    commit('SET_CLIENT_LOADING', false)
                    commit('SET_CLIENT', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_CLIENT_LOADING', false)
                    showErrors(err)
                });
        },
        updateClient({commit}, {id, form}) {
            return axiosAdmin.post(`${clientUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_CLIENT', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteClient({commit}, id) {
            return axiosAdmin.delete(`${clientUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_CLIENT', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
        getClientDueReports({commit}) {
            commit('SET_CLIENT_DUE_REPORTS_LOADING', true)
            return axiosAdmin.get(`${clientUrl}/sale/due-report`)
                .then((res) => {
                    commit("SET_CLIENT_DUE_REPORTS_LOADING", false);
                    commit('SET_CLIENT_DUE_REPORTS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_CLIENT_DUE_REPORTS_LOADING", false);
                    showErrors(err)
                });
        },
        // eslint-disable-next-line no-unused-vars
        sendPaymentReminderSms({commit}, form) {
            return axiosAdmin.post(`${clientUrl}/send/payment-reminder-sms`, form)
                .then((res) => {
                    return res
                }).catch((err) => {
                    throw err
                })
        },
    },
    mutations: {
        SET_CLIENTS_LOADING: (state, loading) => {
            state.clients.loading = loading
        },
        SET_CLIENTS: (state, data) => {
            state.clients.data = data
        },
        ADD_CLIENT: (state, data) => {
            state.clients.data.push(data)
        },
        SET_CLIENT_LOADING: (state, loading) => {
            state.client.loading = loading
        },
        SET_CLIENT: (state, data) => {
            state.client.data = data
        },
        UPDATE_CLIENT: (state, {id, data}) => {
            state.clients.data[state.clients.data.findIndex(client => client.id === id)] = data
        },
        REMOVE_CLIENT: (state, id) => {
            state.clients.data = state.clients.data.filter(data => data.id !== id)
        },
        SET_CLIENT_DUE_REPORTS_LOADING: (state, loading) => {
            state.clientDueReports.loading = loading
        },
        SET_CLIENT_DUE_REPORTS: (state, data) => {
            state.clientDueReports.data = data
        },
    }
}
