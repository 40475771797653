import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const departmentUrl = 'hr/department'

export default {
    namespaced: true,
    state: {
        departments: {
            data: [],
            loading: false
        },
        department: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getDepartments({state, commit}) {
            if (!state.departments.data.length) {
                commit('SET_DEPARTMENTS_LOADING', true)
                return axiosAdmin.get(`${departmentUrl}`)
                    .then((res) => {
                        commit("SET_DEPARTMENTS_LOADING", false);
                        commit('SET_DEPARTMENTS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_DEPARTMENTS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeDepartment({commit}, form) {
            return axiosAdmin.post(`${departmentUrl}`, form)
                .then((res) => {
                    commit('ADD_DEPARTMENT', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getDepartment({commit}, id) {
            commit('SET_DEPARTMENT_LOADING', true)
            return axiosAdmin.get(`${departmentUrl}/${id}`)
                .then((res) => {
                    commit('SET_DEPARTMENT_LOADING', false)
                    commit('SET_DEPARTMENT', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_DEPARTMENT_LOADING', false)
                    showErrors(err)
                });
        },
        updateDepartment({commit}, {id, form}) {
            return axiosAdmin.put(`${departmentUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_DEPARTMENT', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteDepartment({commit}, id) {
            return axiosAdmin.delete(`${departmentUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_DEPARTMENT', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_DEPARTMENTS_LOADING: (state, loading) => {
            state.departments.loading = loading
        },
        SET_DEPARTMENTS: (state, data) => {
            state.departments.data = data
        },
        ADD_DEPARTMENT: (state, data) => {
            state.departments.data.push(data)
        },
        SET_DEPARTMENT_LOADING: (state, loading) => {
            state.department.loading = loading
        },
        SET_DEPARTMENT: (state, data) => {
            state.department.data = data
        },
        UPDATE_DEPARTMENT: (state, {id, data}) => {
            state.departments.data[state.departments.data.findIndex(department => department.id === id)] = data
        },
        REMOVE_DEPARTMENT: (state, id) => {
            state.departments.data = state.departments.data.filter(data => data.id !== id)
        }
    }
}
