import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const orderPaymentUrl = 'restaurant/orderPayment'

export default {
    namespaced: true,
    state: {
        allOrderPayments: {
            data: [],
            loading: false
        },
        orderPayments: {
            data: [],
            meta: {},
            loading: false
        },
        orderPayment: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getAllOrderPayments({commit}, {from_date = '', to_date = '', client_ledger_id = ''}) {
            commit('SET_ALL_ORDER_PAYMENTS_LOADING', true)
            return axiosAdmin.get(`${orderPaymentUrl}?from_date=${from_date}&to_date=${to_date}&client_ledger_id=${client_ledger_id}`)
                .then((res) => {
                    commit("SET_ALL_ORDER_PAYMENTS_LOADING", false);
                    commit('SET_ALL_ORDER_PAYMENTS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_ORDER_PAYMENTS_LOADING", false);
                    showErrors(err)
                });
        },
        getOrderPayments({commit}, {
            page = 1,
            limit = '',
            search = '',
            from_date = '',
            to_date = '',
            client_ledger_id = ''
        }) {
            commit('SET_ORDER_PAYMENTS_LOADING', true)
            return axiosAdmin.get(`${orderPaymentUrl}?page=${page}&limit=${limit}&search=${search}&from_date=${from_date}&to_date=${to_date}&client_ledger_id=${client_ledger_id}`)
                .then((res) => {
                    commit("SET_ORDER_PAYMENTS_LOADING", false);
                    commit('SET_ORDER_PAYMENTS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ORDER_PAYMENTS_LOADING", false);
                    showErrors(err)
                });
        },
        storeOrderPayment({commit}, form) {
            return axiosAdmin.post(`${orderPaymentUrl}`, form)
                .then((res) => {
                    commit('ADD_ORDER_PAYMENTS', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getOrderPayment({commit}, id) {
            commit('ORDER_PAYMENT_LOADING', true)
            return axiosAdmin.get(`${orderPaymentUrl}/${id}`)
                .then((res) => {
                    commit('ORDER_PAYMENT_LOADING', false)
                    commit('SET_ORDER_PAYMENT', res.data.data)
                    return res
                }).catch((err) => {
                    commit('ORDER_PAYMENT_LOADING', false)
                    showErrors(err)
                });
        },
        deleteOrderPayment({commit}, {id, cancel_reason}) {
            return axiosAdmin.delete(`${orderPaymentUrl}/${id}?cancel_reason=${cancel_reason}`)
                .then((res) => {
                    commit('REMOVE_ORDER_PAYMENT', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ALL_ORDER_PAYMENTS_LOADING: (state, loading) => {
            state.allOrderPayments.loading = loading
        },
        SET_ALL_ORDER_PAYMENTS: (state, data) => {
            state.allOrderPayments.data = data
        },
        SET_ORDER_PAYMENTS_LOADING: (state, loading) => {
            state.orderPayments.loading = loading
        },
        SET_ORDER_PAYMENTS: (state, data) => {
            state.orderPayments.data = data.data
            state.orderPayments.meta = data.meta
        },
        ORDER_PAYMENT_LOADING: (state, loading) => {
            state.orderPayment.loading = loading
        },
        SET_ORDER_PAYMENT: (state, data) => {
            state.orderPayment.data = data
        },
        ADD_ORDER_PAYMENTS: (state, data) => {
            data.forEach(value => {
                state.orderPayments.data.push(value)
            })
        },
        REMOVE_ORDER_PAYMENT: (state, id) => {
            state.orderPayments.data = state.orderPayments.data.filter(data => data.id !== id)
        }
    }
}
