import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const paymentRecordUrl = 'inventory/paymentRecord'

export default {
    namespaced: true,
    state: {
        allPaymentRecords: {
            data: [],
            loading: false
        },
        paymentRecords: {
            data: [],
            meta: {},
            loading: false
        },
        paymentRecord: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getPaymentRecordCode() {
            return axiosAdmin.get(`${paymentRecordUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getAllPaymentRecords({commit}, {from_date = '', to_date = '', supplier_ledger_id = ''}) {
            commit('SET_ALL_PAYMENT_RECORDS_LOADING', true)
            return axiosAdmin.get(`${paymentRecordUrl}?from_date=${from_date}&to_date=${to_date}&supplier_ledger_id=${supplier_ledger_id}`)
                .then((res) => {
                    commit("SET_ALL_PAYMENT_RECORDS_LOADING", false);
                    commit('SET_ALL_PAYMENT_RECORDS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_PAYMENT_RECORDS_LOADING", false);
                    showErrors(err)
                });
        },
        getPaymentRecords({commit}, {
            page = 1,
            limit = '',
            search = '',
            from_date = '',
            to_date = '',
            supplier_ledger_id = ''
        }) {
            commit('SET_PAYMENT_RECORDS_LOADING', true)
            return axiosAdmin.get(`${paymentRecordUrl}?page=${page}&limit=${limit}&search=${search}&from_date=${from_date}&to_date=${to_date}&supplier_ledger_id=${supplier_ledger_id}`)
                .then((res) => {
                    commit("SET_PAYMENT_RECORDS_LOADING", false);
                    commit('SET_PAYMENT_RECORDS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PAYMENT_RECORDS_LOADING", false);
                    showErrors(err)
                });
        },
        storePaymentRecord({commit}, form) {
            return axiosAdmin.post(`${paymentRecordUrl}`, form)
                .then((res) => {
                    commit('ADD_PAYMENT_RECORDS', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getPaymentRecord({commit}, id) {
            commit('SET_PAYMENT_RECORD_LOADING', true)
            return axiosAdmin.get(`${paymentRecordUrl}/${id}`)
                .then((res) => {
                    commit('SET_PAYMENT_RECORD_LOADING', false)
                    commit('SET_PAYMENT_RECORD', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_PAYMENT_RECORD_LOADING', false)
                    showErrors(err)
                });
        },
        deletePaymentRecord({commit}, {id, cancel_reason}) {
            return axiosAdmin.delete(`${paymentRecordUrl}/${id}?cancel_reason=${cancel_reason}`)
                .then((res) => {
                    commit('REMOVE_PAYMENT_RECORD', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ALL_PAYMENT_RECORDS_LOADING: (state, loading) => {
            state.allPaymentRecords.loading = loading
        },
        SET_ALL_PAYMENT_RECORDS: (state, data) => {
            state.allPaymentRecords.data = data
        },
        SET_PAYMENT_RECORDS_LOADING: (state, loading) => {
            state.paymentRecords.loading = loading
        },
        SET_PAYMENT_RECORDS: (state, data) => {
            state.paymentRecords.data = data.data
            state.paymentRecords.meta = data.meta
        },
        SET_PAYMENT_RECORD_LOADING: (state, loading) => {
            state.paymentRecord.loading = loading
        },
        SET_PAYMENT_RECORD: (state, data) => {
            state.paymentRecord.data = data
        },
        ADD_PAYMENT_RECORDS: (state, data) => {
            data.forEach(value => {
                state.paymentRecords.data.push(value)
            })
        },
        REMOVE_PAYMENT_RECORD: (state, id) => {
            state.paymentRecords.data = state.paymentRecords.data.filter(data => data.id !== id)
        }
    }
}
