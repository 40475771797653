import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const leaveUrl = 'hr/leave'

export default {
    namespaced: true,
    state: {
        leaves: {
            data: [],
            loading: false
        },
        leave: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getLeaves({state, commit}) {
            if (!state.leaves.data.length) {
                commit('SET_LEAVES_LOADING', true)
                return axiosAdmin.get(`${leaveUrl}`)
                    .then((res) => {
                        commit("SET_LEAVES_LOADING", false);
                        commit('SET_LEAVES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_LEAVES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeLeave({commit}, form) {
            return axiosAdmin.post(`${leaveUrl}`, form)
                .then((res) => {
                    commit('ADD_LEAVE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getLeave({commit}, id) {
            commit('SET_LEAVE_LOADING', true)
            return axiosAdmin.get(`${leaveUrl}/${id}`)
                .then((res) => {
                    commit('SET_LEAVE_LOADING', false)
                    commit('SET_LEAVE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_LEAVE_LOADING', false)
                    showErrors(err)
                });
        },
        updateLeave({commit}, {id, form}) {
            return axiosAdmin.put(`${leaveUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_LEAVE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteLeave({commit}, id) {
            return axiosAdmin.delete(`${leaveUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_LEAVE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_LEAVES_LOADING: (state, loading) => {
            state.leaves.loading = loading
        },
        SET_LEAVES: (state, data) => {
            state.leaves.data = data
        },
        ADD_LEAVE: (state, data) => {
            state.leaves.data.push(data)
        },
        SET_LEAVE_LOADING: (state, loading) => {
            state.leave.loading = loading
        },
        SET_LEAVE: (state, data) => {
            state.leave.data = data
        },
        UPDATE_LEAVE: (state, {id, data}) => {
            state.leaves.data[state.leaves.data.findIndex(leave => leave.id === id)] = data
        },
        REMOVE_LEAVE: (state, id) => {
            state.leaves.data = state.leaves.data.filter(data => data.id !== id)
        }
    }
}
