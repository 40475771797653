import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/scss/bootstrap.scss'
//import '@/assets/scss/main.css'

import './assets/style.css'
import '@/assets/nprogress.css'

//components
import VModal from "@/components/core/VModal";
import VSubmitButton from "@/components/core/VSubmitButton"
import VInput from "@/components/core/VInput"
import VSelect from "@/components/core/VSelect"
import VCheckbox from "@/components/core/VCheckbox"
import ProgressBar from "@/components/ProgressBar"
import TableLoading from "@/components/TableLoading"
import VDateInput from "@/components/core/VDateInput"
import VTextArea from "@/components/core/VTextArea.vue";
import FileUpload from "@/components/FileUpload.vue";
import VCkEditor from "@/components/core/VCkEditor.vue"
import VTable from "@/components/VTable.vue";
import VMultiSelect from "@/components/core/VMultiSelect.vue";
//my plugins
import myPlugins from "@/plugins/my-plugins";
import print from "vue3-print-nb";
import {permissionAccess} from "@/helpers/checkPermission";

createApp(App)
    .use(router)
    .use(store)
    .use(myPlugins)
    .component('VModal', VModal)
    .component('VSubmitButton', VSubmitButton)
    .component('VInput', VInput)
    .component('VSelect', VSelect)
    .component('VCheckbox', VCheckbox)
    .component('ProgressBar', ProgressBar)
    .component('TableLoading', TableLoading)
    .component('VDateInput', VDateInput)
    .component('VTextArea', VTextArea)
    .component('FileUpload', FileUpload)
    .component('VCkEditor', VCkEditor)
    .component('VTable', VTable)
    .component('VMultiSelect',VMultiSelect)
    .directive('print', print)
    .directive('can', permissionAccess)
    .mount('#app')
