import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const hrReportUrl = 'hr/report'

export default {
    namespaced: true,
    state: {
        salaryLedger: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getSalaryLedger({commit},{employee_id}) {
            commit('SET_SALARY_LEDGER_LOADING', true)
            return axiosAdmin.get(`${hrReportUrl}/employee/${employee_id}/salary-ledger`)
                .then((res) => {
                    commit("SET_SALARY_LEDGER_LOADING", false);
                    commit('SET_SALARY_LEDGER', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_SALARY_LEDGER_LOADING", false);
                    showErrors(err)
                });
        }
    },
    mutations: {
        SET_SALARY_LEDGER_LOADING: (state, loading) => {
            state.salaryLedger.loading = loading
        },
        SET_SALARY_LEDGER: (state, data) => {
            state.salaryLedger.data = data
        }
    }
}
