import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const employeeUrl = 'hr/employee'

export default {
    namespaced: true,
    state: {
        employees: {
            data: [],
            loading: false
        },
        employee: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getEmployeeCode(){
            return axiosAdmin.get(`${employeeUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getEmployees({state, commit}) {
            if (!state.employees.data.length) {
                commit('SET_EMPLOYEES_LOADING', true)
                return axiosAdmin.get(`${employeeUrl}`)
                    .then((res) => {
                        commit("SET_EMPLOYEES_LOADING", false);
                        commit('SET_EMPLOYEES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_EMPLOYEES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeEmployee({commit}, form) {
            return axiosAdmin.post(`${employeeUrl}`, form)
                .then((res) => {
                    commit('ADD_EMPLOYEE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        // eslint-disable-next-line no-unused-vars
        importEmployee({commit}, data) {
            return axiosAdmin.post(`hr/employeeImport`, data)
                .then((res) => {
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getEmployee({commit}, id) {
            commit('SET_EMPLOYEE_LOADING', true)
            return axiosAdmin.get(`${employeeUrl}/${id}`)
                .then((res) => {
                    commit('SET_EMPLOYEE_LOADING', false)
                    commit('SET_EMPLOYEE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_EMPLOYEE_LOADING', false)
                    showErrors(err)
                });
        },
        updateEmployee({commit}, {id, form}) {
            return axiosAdmin.post(`${employeeUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_EMPLOYEE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteEmployee({commit}, id) {
            return axiosAdmin.delete(`${employeeUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_EMPLOYEE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
        updateStatus({commit}, id) {
            return axiosAdmin.put(`${employeeUrl}/${id}/updateStatus`)
                .then((res) => {
                    commit('SET_STATUS', {id, data:res.data.status})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
    },
    mutations: {
        SET_EMPLOYEES_LOADING: (state, loading) => {
            state.employees.loading = loading
        },
        SET_EMPLOYEES: (state, data) => {
            state.employees.data = data
        },
        ADD_EMPLOYEE: (state, data) => {
            state.employees.data.push(data)
        },
        SET_EMPLOYEE_LOADING: (state, loading) => {
            state.employee.loading = loading
        },
        SET_EMPLOYEE: (state, data) => {
            state.employee.data = data
        },
        UPDATE_EMPLOYEE: (state, {id, data}) => {
            state.employees.data[state.employees.data.findIndex(employee => employee.id === id)] = data
        },
        REMOVE_EMPLOYEE: (state, id) => {
            state.employees.data = state.employees.data.filter(data => data.id !== id)
        },
        SET_STATUS: (state, {id, status}) => {
            state.employees.data[state.employees.data.findIndex(employee => employee.id === id)].status = status
        },
    }
}
