import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const unitUrl = 'inventory/unit'

export default {
    namespaced: true,
    state: {
        units: {
            data: [],
            loading: false
        },
        unit: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getUnits({state, commit}) {
            if (!state.units.data.length) {
                commit('SET_UNITS_LOADING', true)
                return axiosAdmin.get(`${unitUrl}`)
                    .then((res) => {
                        commit("SET_UNITS_LOADING", false);
                        commit('SET_UNITS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_UNITS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeUnit({commit}, form) {
            return axiosAdmin.post(`${unitUrl}`, form)
                .then((res) => {
                    commit('ADD_UNIT', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getUnit({commit}, id) {
            commit('SET_UNIT_LOADING', true)
            return axiosAdmin.get(`${unitUrl}/${id}`)
                .then((res) => {
                    commit('SET_UNIT_LOADING', false)
                    commit('SET_UNIT', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_UNIT_LOADING', false)
                    showErrors(err)
                });
        },
        updateUnit({commit}, {id, form}) {
            return axiosAdmin.put(`${unitUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_UNIT', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteUnit({commit}, id) {
            return axiosAdmin.delete(`${unitUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_UNIT', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_UNITS_LOADING: (state, loading) => {
            state.units.loading = loading
        },
        SET_UNITS: (state, data) => {
            state.units.data = data
        },
        ADD_UNIT: (state, data) => {
            state.units.data.push(data)
        },
        SET_UNIT_LOADING: (state, loading) => {
            state.unit.loading = loading
        },
        SET_UNIT: (state, data) => {
            state.unit.data = data
        },
        UPDATE_UNIT: (state, {id, data}) => {
            state.units.data[state.units.data.findIndex(unit => unit.id === id)] = data
        },
        REMOVE_UNIT: (state, id) => {
            state.units.data = state.units.data.filter(data => data.id !== id)
        }
    }
}
