import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const bankUrl = 'setting/bank'

export default {
    namespaced: true,
    state: {
        banks: {
            data: [],
            loading: false
        },
        bank: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getBanks({commit}) {
            commit('SET_BANKS_LOADING', true)
            return axiosAdmin.get(`${bankUrl}`)
                .then((res) => {
                    commit("SET_BANKS_LOADING", false);
                    commit('SET_BANKS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_BANKS_LOADING", false);
                    showErrors(err)
                });
        },
        storeBank({commit}, form) {
            return axiosAdmin.post(`${bankUrl}`, form)
                .then((res) => {
                    commit('ADD_BANK', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getBank({commit}, id) {
            commit('SET_BANK_LOADING', true)
            return axiosAdmin.get(`${bankUrl}/${id}`)
                .then((res) => {
                    commit('SET_BANK_LOADING', false)
                    commit('SET_BANK', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_BANK_LOADING', false)
                    showErrors(err)
                });
        },
        updateBank({commit}, {id, form}) {
            return axiosAdmin.put(`${bankUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_BANK', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteBank({commit}, id) {
            return axiosAdmin.delete(`${bankUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_BANK', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_BANKS_LOADING: (state, loading) => {
            state.banks.loading = loading
        },
        SET_BANKS: (state, data) => {
            state.banks.data = data
        },
        ADD_BANK: (state, data) => {
            state.banks.data.push(data)
        },
        SET_BANK_LOADING: (state, loading) => {
            state.bank.loading = loading
        },
        SET_BANK: (state, data) => {
            state.bank.data = data
        },
        UPDATE_BANK: (state, {id, data}) => {
            state.banks.data[state.banks.data.findIndex(bank => bank.id === id)] = data
        },
        REMOVE_BANK: (state, id) => {
            state.banks.data = state.banks.data.filter(data => data.id !== id)
        }
    }
}
