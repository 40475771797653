import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const salaryPaymentUrl = 'hr/salaryPayment'

export default {
    namespaced: true,
    state: {
        salaryPayments: {
            data: [],
            loading: false
        },
        salaryPayment: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getSalaryPayments({commit}, {
            employee_id = '',
            month_id = '',
            from_date = '',
            to_date = '',
            payment_status = ''
        }) {
            commit('SET_SALARY_PAYMENTS_LOADING', true)
            return axiosAdmin.get(`${salaryPaymentUrl}?employee_id=${employee_id}&month_id=${month_id}&from_date=${from_date}&to_date=${to_date}&payment_status=${payment_status}`)
                .then((res) => {
                    commit("SET_SALARY_PAYMENTS_LOADING", false);
                    commit('SET_SALARY_PAYMENTS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_SALARY_PAYMENTS_LOADING", false);
                    showErrors(err)
                });
        },
        storeSalaryPayment({commit}, form) {
            return axiosAdmin.post(`${salaryPaymentUrl}`, form)
                .then((res) => {
                    commit('ADD_SALARY_PAYMENT', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getSalaryPayment({commit}, id) {
            commit('SET_SALARY_PAYMENT_LOADING', true)
            return axiosAdmin.get(`${salaryPaymentUrl}/${id}`)
                .then((res) => {
                    commit('SET_SALARY_PAYMENT_LOADING', false)
                    commit('SET_SALARY_PAYMENT', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_SALARY_PAYMENT_LOADING', false)
                    showErrors(err)
                });
        },

        deleteSalaryPayment({commit}, {id, cancelled_reason}) {
            return axiosAdmin.delete(`${salaryPaymentUrl}/${id}?cancelled_reason=${cancelled_reason}`)
                .then((res) => {
                    commit('REMOVE_SALARY_PAYMENT', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_SALARY_PAYMENTS_LOADING: (state, loading) => {
            state.salaryPayments.loading = loading
        },
        SET_SALARY_PAYMENTS: (state, data) => {
            state.salaryPayments.data = data
        },
        ADD_SALARY_PAYMENT: (state, data) => {
            state.salaryPayments.data.push(data)
        },
        SET_SALARY_PAYMENT_LOADING: (state, loading) => {
            state.salaryPayment.loading = loading
        },
        SET_SALARY_PAYMENT: (state, data) => {
            state.salaryPayment.data = data
        },
        REMOVE_SALARY_PAYMENT: (state, id) => {
            state.salaryPayments.data = state.salaryPayments.data.filter(data => data.id !== id)
        }
    }
}
