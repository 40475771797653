import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const payableChargeUrl = 'hr/payableCharge'

export default {
    namespaced: true,
    state: {
        payableCharges: {
            data: [],
            loading: false
        },
        payableCharge: {
            data: {},
            loading: false
        }
    },
    getters: {
        duePayableCharges: (state) => {
            return {
                loading: state.payableCharges.loading,
                data: state.payableCharges.data.filter(payableCharge => payableCharge.due_amount > 0)
            }
        }
    },
    actions: {
        getPayableCharges({commit}, {
            employee_id = '',
            month_id = '',
            from_date = '',
            to_date = '',
            payable_charge_status = ''
        }) {
            commit('SET_PAYABLE_CHARGES_LOADING', true)
            return axiosAdmin.get(`${payableChargeUrl}?employee_id=${employee_id}&month_id=${month_id}&from_date=${from_date}&to_date=${to_date}&payable_charge_status=${payable_charge_status}`)
                .then((res) => {
                    commit("SET_PAYABLE_CHARGES_LOADING", false);
                    commit('SET_PAYABLE_CHARGES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PAYABLE_CHARGES_LOADING", false);
                    showErrors(err)
                });
        },
        storePayableCharge({dispatch}, form) {
            return axiosAdmin.post(`${payableChargeUrl}`, form)
                .then((res) => {
                    dispatch('getPayableCharges', {month_id: form.month_id})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getPayableCharge({commit}, id) {
            commit('SET_PAYABLE_CHARGE_LOADING', true)
            return axiosAdmin.get(`${payableChargeUrl}/${id}`)
                .then((res) => {
                    commit('SET_PAYABLE_CHARGE_LOADING', false)
                    commit('SET_PAYABLE_CHARGE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_PAYABLE_CHARGE_LOADING', false)
                    showErrors(err)
                });
        },
        updatePayableCharge({commit}, {id, form}) {
            return axiosAdmin.put(`${payableChargeUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_PAYABLE_CHARGE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deletePayableCharge({commit}, {id, cancelled_reason}) {
            return axiosAdmin.delete(`${payableChargeUrl}/${id}?cancelled_reason=${cancelled_reason}`)
                .then((res) => {
                    commit('REMOVE_PAYABLE_CHARGE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_PAYABLE_CHARGES_LOADING: (state, loading) => {
            state.payableCharges.loading = loading
        },
        SET_PAYABLE_CHARGES: (state, data) => {
            state.payableCharges.data = data
        },
        ADD_PAYABLE_CHARGE: (state, data) => {
            state.payableCharges.data.push(data)
        },
        SET_PAYABLE_CHARGE_LOADING: (state, loading) => {
            state.payableCharge.loading = loading
        },
        SET_PAYABLE_CHARGE: (state, data) => {
            state.payableCharge.data = data
        },
        UPDATE_PAYABLE_CHARGE: (state, {id, data}) => {
            state.payableCharges.data[state.payableCharges.data.findIndex(payableCharge => payableCharge.id === id)] = data
        },
        REMOVE_PAYABLE_CHARGE: (state, id) => {
            state.payableCharges.data = state.payableCharges.data.filter(data => data.id !== id)
        }
    }
}
