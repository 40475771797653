import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const menuItemUrl = 'restaurant/menuItem'

export default {
    namespaced: true,
    state: {
        menuItems: {
            data: [],
            loading: false
        },
        menuItem: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getMenuItems({commit}) {
            commit('SET_MENU_ITEMS_LOADING', true)
            return axiosAdmin.get(`${menuItemUrl}`)
                .then((res) => {
                    commit("SET_MENU_ITEMS_LOADING", false);
                    commit('SET_MENU_ITEMS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_MENU_ITEMS_LOADING", false);
                    showErrors(err)
                });
        },
        storeMenuItem({dispatch}, form) {
            return axiosAdmin.post(`${menuItemUrl}`, form)
                .then((res) => {
                    dispatch("getMenuItems")
                    return res;
                }).catch((err) => {
                    throw err
                })
        },
        deleteMenuItem({commit}, id) {
            return axiosAdmin.delete(`${menuItemUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_MENU_ITEM', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_MENU_ITEMS_LOADING: (state, loading) => {
            state.menuItems.loading = loading
        },
        SET_MENU_ITEMS: (state, data) => {
            state.menuItems.data = data
        },
        ADD_MENU_ITEM: (state, data) => {
            state.menuItems.data.push(data)
        }
    }
}
