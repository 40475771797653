import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const employeeAttendanceUrl = 'hr/employeeAttendance'

export default {
    namespaced: true,
    state: {
        employeeAttendances: {
            data: [],
            loading: false
        },
        employeeAttendance: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getEmployeeAttendances({commit}, {date = '', department_id = '', designation_id = ''}) {
            commit('SET_EMPLOYEE_ATTENDANCES_LOADING', true)
            return axiosAdmin.get(`${employeeAttendanceUrl}?date=${date}&department_id=${department_id}&designation_id=${designation_id}`)
                .then((res) => {
                    commit("SET_EMPLOYEE_ATTENDANCES_LOADING", false);
                    commit('SET_EMPLOYEE_ATTENDANCES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_EMPLOYEE_ATTENDANCES_LOADING", false);
                    showErrors(err)
                });
        },
        // eslint-disable-next-line no-unused-vars
        storeEmployeeAttendance({commit},form) {
            return axiosAdmin.post(`${employeeAttendanceUrl}`, form)
                .then((res) => {
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getEmployeeAttendance({commit}, id) {
            commit('SET_EMPLOYEE_ATTENDANCE_LOADING', true)
            return axiosAdmin.get(`${employeeAttendanceUrl}/${id}`)
                .then((res) => {
                    commit('SET_EMPLOYEE_ATTENDANCE_LOADING', false)
                    commit('SET_EMPLOYEE_ATTENDANCE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_EMPLOYEE_ATTENDANCE_LOADING', false)
                    showErrors(err)
                });
        },
    },
    mutations: {
        SET_EMPLOYEE_ATTENDANCES_LOADING: (state, loading) => {
            state.employeeAttendances.loading = loading
        },
        SET_EMPLOYEE_ATTENDANCES: (state, data) => {
            state.employeeAttendances.data = data
        },
        SET_EMPLOYEE_ATTENDANCE_LOADING: (state, loading) => {
            state.employeeAttendance.loading = loading
        },
        SET_EMPLOYEE_ATTENDANCE: (state, data) => {
            state.employeeAttendance.data = data
        }
    }
}
