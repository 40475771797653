<template>
  <label v-if="label" :for="id" class="form-label">{{ label }}</label>
  <Multiselect
      :id="id"
      :label="nameProp"
      :value="modelValue"
      :customLabel="label"
      @input="updateModelValue"
      :disabled="disabled"
      valueProp="id"
      :searchable="true"
      track-by="name"
      :options="options"
      :placeholder="'Select '+ label"
  />
  <div v-if="error.$error" class="text-danger">
    {{ error.$errors[0].$message }}
  </div>
</template>

<script>
import '@vueform/multiselect/themes/default.css'
import Multiselect from "@vueform/multiselect"
export default {
  components:{Multiselect},
  props: {
    id: {
      type: String
    },
    selectClass: {
      type: String,
      default: 'form-select'
    },
    label: {
      type: String,
      default:''
    },
    error: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    },

    modelValue: {
      required: true
    },
    options: {
      required: true,
      type: Array
    },
    subOptions: {
      type: String,
    },
    valueProp: {
      type: String,
      default: 'id'
    },
    nameProp: {
      type: String
    },
    subNameProp: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
    setup(props,{emit}){
      function updateModelValue(value){
        emit('update:modelValue',value)
      }

      return {
        updateModelValue
      }
  }
}
</script>