import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const accountReportUrl = 'account/report'

export default {
    namespaced: true,
    state: {
        generalLedgerReport: {
            data: [],
            opening_balance: {},
            loading: false
        },
        balanceSheet: {
            assets: [],
            liabilities: [],
            loading: false
        },
        profitLoss: {
            income: [],
            expense: [],
            loading: false
        },
        dayBooks:{
            data:[],
            loading:false
        },
        trialBalance:{
            data:[],
            loading:false
        }
    },
    getters: {},
    actions: {
        getGeneralLedgerReport({commit}, {ledger_id, from_date, to_date}) {
            commit('SET_GENERAL_LEDGERS_LOADING', true)
            return axiosAdmin.get(`${accountReportUrl}/general-ledger?ledger_id=${ledger_id}&from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_GENERAL_LEDGERS_LOADING", false);
                    commit('SET_GENERAL_LEDGERS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_GENERAL_LEDGERS_LOADING", false);
                    showErrors(err)
                });
        },
        getBalanceSheet({commit}, {from_date, to_date}) {
            commit('SET_BALANCE_SHEET_LOADING', true)
            return axiosAdmin.get(`${accountReportUrl}/balance-sheet?from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_BALANCE_SHEET_LOADING", false);
                    commit('SET_BALANCE_SHEETS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_BALANCE_SHEET_LOADING", false);
                    showErrors(err)
                });
        },

        getProfitLoss({commit}, {from_date, to_date}) {
            commit('SET_PROFIT_LOSS_LOADING', true)
            return axiosAdmin.get(`${accountReportUrl}/profit-loss?from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_PROFIT_LOSS_LOADING", false);
                    commit('SET_PROFIT_LOSS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PROFIT_LOSS_LOADING", false);
                    showErrors(err)
                });
        },

        getDayBooks({commit}, {from_date, to_date}) {
            commit('SET_DAY_BOOKS_LOADING', true)
            return axiosAdmin.get(`${accountReportUrl}/day-book?from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_DAY_BOOKS_LOADING", false);
                    commit('SET_DAY_BOOKS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_DAY_BOOKS_LOADING", false);
                    showErrors(err)
                });
        },
        getTrialBalance({commit}, {from_date, to_date}) {
            commit('SET_TRIAL_BALANCE_LOADING', true)
            return axiosAdmin.get(`${accountReportUrl}/trial-balance?from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_TRIAL_BALANCE_LOADING", false);
                    commit('SET_TRIAL_BALANCE', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_TRIAL_BALANCE_LOADING", false);
                    showErrors(err)
                });
        }
    },
    mutations: {
        SET_GENERAL_LEDGERS_LOADING: (state, loading) => {
            state.generalLedgerReport.loading = loading
        },
        SET_GENERAL_LEDGERS: (state, data) => {
            state.generalLedgerReport.data = data.data
            state.generalLedgerReport.opening_balance = data.opening_balance
        },
        SET_BALANCE_SHEET_LOADING: (state, loading) => {
            state.balanceSheet.loading = loading
        },
        SET_BALANCE_SHEETS: (state, data) => {
            state.balanceSheet.assets = data.assets
            state.balanceSheet.liabilities = data.liabilities
        },
        SET_PROFIT_LOSS_LOADING: (state, loading) => {
            state.profitLoss.loading = loading
        },
        SET_PROFIT_LOSS: (state, data) => {
            state.profitLoss.income = data.income
            state.profitLoss.expense = data.expense
        },
        SET_DAY_BOOKS_LOADING: (state, loading) => {
            state.dayBooks.loading = loading
        },
        SET_DAY_BOOKS: (state, data) => {
            state.dayBooks.data = data
        },
        SET_TRIAL_BALANCE_LOADING: (state, loading) => {
            state.trialBalance.loading = loading
        },
        SET_TRIAL_BALANCE: (state, data) => {
            state.trialBalance.data = data
        }
    }
}
