import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const productOpeningUrl = 'inventory/productOpening'

export default {
    namespaced: true,
    state: {
        productOpenings: {
            data: [],
            loading: false
        },
        productOpening: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getProductOpenings({state, commit}) {
            if (!state.productOpenings.data.length) {
                commit('SET_PRODUCT_OPENINGS_LOADING', true)
                return axiosAdmin.get(`${productOpeningUrl}`)
                    .then((res) => {
                        commit("SET_PRODUCT_OPENINGS_LOADING", false);
                        commit('SET_PRODUCT_OPENINGS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_PRODUCT_OPENINGS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeProductOpening({commit}, form) {
            return axiosAdmin.post(`${productOpeningUrl}`, form)
                .then((res) => {
                    commit('ADD_PRODUCT_OPENING', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getProductOpening({commit}, id) {
            commit('SET_PRODUCT_OPENING_LOADING', true)
            return axiosAdmin.get(`${productOpeningUrl}/${id}`)
                .then((res) => {
                    commit('SET_PRODUCT_OPENING_LOADING', false)
                    commit('SET_PRODUCT_OPENING', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_PRODUCT_OPENING_LOADING', false)
                    showErrors(err)
                });
        },
        updateProductOpening({commit}, {id, form}) {
            return axiosAdmin.put(`${productOpeningUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_PRODUCT_OPENING', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteProductOpening({commit}, id) {
            return axiosAdmin.delete(`${productOpeningUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_PRODUCT_OPENING', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_PRODUCT_OPENINGS_LOADING: (state, loading) => {
            state.productOpenings.loading = loading
        },
        SET_PRODUCT_OPENINGS: (state, data) => {
            state.productOpenings.data = data
        },
        ADD_PRODUCT_OPENING: (state, data) => {
            state.productOpenings.data.push(data)
        },
        SET_PRODUCT_OPENING_LOADING: (state, loading) => {
            state.productOpening.loading = loading
        },
        SET_PRODUCT_OPENING: (state, data) => {
            state.productOpening.data = data
        },
        UPDATE_PRODUCT_OPENING: (state, {id, data}) => {
            state.productOpenings.data[state.productOpenings.data.findIndex(productOpening => productOpening.id === id)] = data
        },
        REMOVE_PRODUCT_OPENING: (state, id) => {
            state.productOpenings.data = state.productOpenings.data.filter(data => data.id !== id)
        }
    }
}
