import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const smsTemplateUrl = 'sms/smsTemplate'

export default {
    namespaced: true,
    state: {
        smsTemplates: {
            data: [],
            loading: false
        },
        smsTemplate: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getSmsTemplates({state, commit}) {
            if (!state.smsTemplates.data.length) {
                commit('SET_SMS_TEMPLATES_LOADING', true)
                return axiosAdmin.get(`${smsTemplateUrl}`)
                    .then((res) => {
                        commit("SET_SMS_TEMPLATES_LOADING", false);
                        commit('SET_SMS_TEMPLATES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_SMS_TEMPLATES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeSmsTemplate({commit}, form) {
            return axiosAdmin.post(`${smsTemplateUrl}`, form)
                .then((res) => {
                    commit('ADD_SMS_TEMPLATE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getSmsTemplate({commit}, id) {
            commit('SET_SMS_TEMPLATE_LOADING', true)
            return axiosAdmin.get(`${smsTemplateUrl}/${id}`)
                .then((res) => {
                    commit('SET_SMS_TEMPLATE_LOADING', false)
                    commit('SET_SMS_TEMPLATE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_SMS_TEMPLATE_LOADING', false)
                    showErrors(err)
                });
        },
        updateSmsTemplate({commit}, {id, form}) {
            return axiosAdmin.put(`${smsTemplateUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_SMS_TEMPLATE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteSmsTemplate({commit}, id) {
            return axiosAdmin.delete(`${smsTemplateUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_SMS_TEMPLATE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_SMS_TEMPLATES_LOADING: (state, loading) => {
            state.smsTemplates.loading = loading
        },
        SET_SMS_TEMPLATES: (state, data) => {
            state.smsTemplates.data = data
        },
        ADD_SMS_TEMPLATE: (state, data) => {
            state.smsTemplates.data.push(data)
        },
        SET_SMS_TEMPLATE_LOADING: (state, loading) => {
            state.smsTemplate.loading = loading
        },
        SET_SMS_TEMPLATE: (state, data) => {
            state.smsTemplate.data = data
        },
        UPDATE_SMS_TEMPLATE: (state, {id, data}) => {
            state.smsTemplates.data[state.smsTemplates.data.findIndex(smsTemplate => smsTemplate.id === id)] = data
        },
        REMOVE_SMS_TEMPLATE: (state, id) => {
            state.smsTemplates.data = state.smsTemplates.data.filter(data => data.id !== id)
        }
    }
}
