import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const restaurantOrderUrl = 'restaurant/restaurantOrder'

export default {
    namespaced: true,
    state: {
        allRestaurantOrders: {
            data: [],
            loading: false
        },
        clientDuePaymentOrders: {
            data: [],
            loading: false
        },
        restaurantOrders: {
            data: [],
            meta:{},
            loading: false
        },
        restaurantOrder: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getAllRestaurantOrders({commit}, {client_ledger_id}) {
            commit('SET_ALL_RESTAURANT_ORDERS_LOADING', true)
            return axiosAdmin.get(`${restaurantOrderUrl}/list/all?client_ledger_id=${client_ledger_id}`)
                .then((res) => {
                    commit("SET_ALL_RESTAURANT_ORDERS_LOADING", false);
                    commit('SET_ALL_RESTAURANT_ORDERS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_RESTAURANT_ORDERS_LOADING", false);
                    showErrors(err)
                });
        },
        getClientDuePaymentOrders({commit}, {
            client_id,
            order_payment_status = 'due'
        }) {
            commit('SET_CLIENT_DUE_PAYMENT_ORDERS_LOADING', true)
            return axiosAdmin.get(`${restaurantOrderUrl}/list/all?client_id=${client_id}&order_payment_status=${order_payment_status}`)
                .then((res) => {
                    commit("SET_CLIENT_DUE_PAYMENT_ORDERS_LOADING", false);
                    commit('SET_CLIENT_DUE_PAYMENT_ORDERS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_CLIENT_DUE_PAYMENT_ORDERS_LOADING", false);
                    showErrors(err)
                });
        },
        getRestaurantOrders({commit}, {
            page = 1,
            limit = '',
            search = '',
            client_id = '',
            from_date = '',
            to_date = '',
            waiter_id=''
        }) {
            commit('SET_RESTAURANT_ORDERS_LOADING', true)
            return axiosAdmin.get(`${restaurantOrderUrl}?page=${page}&limit=${limit}&search=${search}&client_id=${client_id}&from_date=${from_date}&to_date=${to_date}&waiter_id=${waiter_id}`)
                .then((res) => {
                    commit("SET_RESTAURANT_ORDERS_LOADING", false);
                    commit('SET_RESTAURANT_ORDERS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_RESTAURANT_ORDERS_LOADING", false);
                    showErrors(err)
                });
        },
        storeRestaurantOrder({commit}, form) {
            return axiosAdmin.post(`${restaurantOrderUrl}`, form)
                .then((res) => {
                    commit('ADD_RESTAURANT_ORDER', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getRestaurantOrder({commit}, id) {
            commit('SET_RESTAURANT_ORDER_LOADING', true)
            return axiosAdmin.get(`${restaurantOrderUrl}/${id}`)
                .then((res) => {
                    commit('SET_RESTAURANT_ORDER_LOADING', false)
                    commit('SET_RESTAURANT_ORDER', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_RESTAURANT_ORDER_LOADING', false)
                    showErrors(err)
                });
        },
        deleteRestaurantOrder({commit}, {id, cancel_reason}) {
            return axiosAdmin.delete(`${restaurantOrderUrl}/${id}?cancel_reason=${cancel_reason}`)
                .then((res) => {
                    commit('REMOVE_RESTAURANT_ORDER', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ALL_RESTAURANT_ORDERS_LOADING: (state, loading) => {
            state.allRestaurantOrders.loading = loading
        },
        SET_ALL_RESTAURANT_ORDERS: (state, data) => {
            state.allRestaurantOrders.data = data
        },
        SET_CLIENT_DUE_PAYMENT_ORDERS_LOADING: (state, loading) => {
            state.clientDuePaymentOrders.loading = loading
        },
        SET_CLIENT_DUE_PAYMENT_ORDERS: (state, data) => {
            state.clientDuePaymentOrders.data = data
        },
        SET_RESTAURANT_ORDERS_LOADING: (state, loading) => {
            state.restaurantOrders.loading = loading
        },
        SET_RESTAURANT_ORDERS: (state, data) => {
            state.restaurantOrders.data = data.data
            state.restaurantOrders.meta = data.meta
        },
        ADD_RESTAURANT_ORDER: (state, data) => {
            state.restaurantOrders.data.push(data)
        },
        SET_RESTAURANT_ORDER_LOADING: (state, loading) => {
            state.restaurantOrder.loading = loading
        },
        SET_RESTAURANT_ORDER: (state, data) => {
            state.restaurantOrder.data = data
        },
        REMOVE_RESTAURANT_ORDER: (state, id) => {
            state.restaurantOrders.data = state.restaurantOrders.data.filter(data => data.id !== id)
        }
    }
}
