import axios from "axios";
import store from "@/store";
import router from "@/router";

//axios for admin
const axiosForAdmin = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/admin/`
})

axiosForAdmin.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${store.state.user.access_token}`
    return config;
})

axiosForAdmin.interceptors.response.use(response => {
    return response;
}, async error => {
    if (error.response.status === 401) {
        await store.commit('LOGOUT');
        await router.push({name: 'Login'})
    } else if (error.response.status === 404) {
        await router.push({name: '404Page'})
    }
    throw error;

})

export const axiosAdmin=axiosForAdmin

//axios for frontend
export const axiosFront = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/`
})

