import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const menuCategoryUrl = 'restaurant/menuCategory'

export default {
    namespaced: true,
    state: {
        menuCategories: {
            data: [],
            loading: false
        },
        menuCategory: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getMenuCategories({commit}) {
            commit('SET_MENU_CATEGORIES_LOADING', true)
            return axiosAdmin.get(`${menuCategoryUrl}`)
                .then((res) => {
                    commit("SET_MENU_CATEGORIES_LOADING", false);
                    commit('SET_MENU_CATEGORIES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_MENU_CATEGORIES_LOADING", false);
                    showErrors(err)
                });
        },
        storeMenuCategory({commit}, form) {
            return axiosAdmin.post(`${menuCategoryUrl}`, form)
                .then((res) => {
                    commit('ADD_MENU_CATEGORY', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getMenuCategory({commit}, id) {
            commit('SET_MENU_CATEGORY_LOADING', true)
            return axiosAdmin.get(`${menuCategoryUrl}/${id}`)
                .then((res) => {
                    commit('SET_MENU_CATEGORY_LOADING', false)
                    commit('SET_MENU_CATEGORY', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_MENU_CATEGORY_LOADING', false)
                    showErrors(err)
                });
        },
        updateMenuCategory({commit}, {id, form}) {
            return axiosAdmin.put(`${menuCategoryUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_MENU_CATEGORY', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteMenuCategory({commit}, id) {
            return axiosAdmin.delete(`${menuCategoryUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_MENU_CATEGORY', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_MENU_CATEGORIES_LOADING: (state, loading) => {
            state.menuCategories.loading = loading
        },
        SET_MENU_CATEGORIES: (state, data) => {
            state.menuCategories.data = data
        },
        ADD_MENU_CATEGORY: (state, data) => {
            state.menuCategories.data.push(data)
        },
        SET_MENU_CATEGORY_LOADING: (state, loading) => {
            state.menuCategory.loading = loading
        },
        SET_MENU_CATEGORY: (state, data) => {
            state.menuCategory.data = data
        },
        UPDATE_MENU_CATEGORY: (state, {id, data}) => {
            state.menuCategories.data[state.menuCategories.data.findIndex(menuCategory => menuCategory.id === id)] = data
        },
        REMOVE_MENU_CATEGORY: (state, id) => {
            state.menuCategories.data = state.menuCategories.data.filter(data => data.id !== id)
        }
    }
}
