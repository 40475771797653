import {createStore} from "vuex";
import {axiosAdmin, axiosFront} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors";
import {camelCase} from "eslint-plugin-vue/lib/utils/casing";

const store = createStore({
    state: {
        user: {
            access_token: localStorage.getItem("access_token"),
            permissions: localStorage.getItem("permissions") ? localStorage.getItem('permissions').split(',') : []
        },
        dashboard: {
            data: {},
            loading: false
        },
        restaurantDashboard: {
            data: {},
            loading: false
        },
        pmsDashboard: {
            data: {},
            loading: false
        },
        profile: {
            data: {}
        },
    },
    getters: {},
    actions: {
        login({commit}, user) {
            return axiosFront.post('login', user)
                .then(({data}) => {
                    commit('SET_TOKEN', data.access_token)
                    commit('SET_USER_PERMISSIONS', data.permissions)
                    return data;
                })
        },
        logout({commit}) {
            return axiosAdmin.post('logout')
                .then((res) => {
                    commit('LOGOUT')
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
        getProfile({state, commit}) {
            if (!Object.keys(state.profile.data).length) {
                return axiosAdmin.get('profile')
                    .then((res) => {
                        commit('SET_PROFILE', res.data.data)
                        return res
                    }).catch((err) => {
                        throw err;
                    });
            }
        },
        updateProfile({commit}, data) {
            return axiosAdmin.post(`profile/updateProfile`, data)
                .then((res) => {
                    commit('SET_PROFILE', res.data.data)
                    return res
                })
                .catch((err) => {
                    throw err;
                });
        },
        // eslint-disable-next-line no-unused-vars
        updatePassword({commit}, data) {
            return axiosAdmin.put(`profile/updatePassword`, data)
                .then((res) => {
                    return res
                }).catch((err) => {
                    throw err;
                })
        },
        getDashboardData({commit}) {
            commit("SET_DASHBOARD_LOADING", true)
            return axiosAdmin.get(`dashboard`)
                .then((res) => {
                    commit("SET_DASHBOARD_LOADING", false)
                    commit("SET_DASHBOARD_DATA", res.data)
                    return res
                }).catch((err) => {
                    commit("SET_DASHBOARD_LOADING", false)
                    showErrors(err)
                })
        },
        getRestaurantDashboardData({commit}) {
            commit("SET_RESTAURANT_DASHBOARD_LOADING", true)
            return axiosAdmin.get(`restaurant/dashboard`)
                .then((res) => {
                    commit("SET_RESTAURANT_DASHBOARD_LOADING", false)
                    commit("SET_RESTAURANT_DASHBOARD_DATA", res.data)
                    return res
                }).catch((err) => {
                    commit("SET_RESTAURANT_DASHBOARD_LOADING", false)
                    showErrors(err)
                })
        },
        getPmsDashboardData({commit}) {
            commit("SET_PMS_DASHBOARD_LOADING", true)
            return axiosAdmin.get(`pms/dashboard`)
                .then((res) => {
                    commit("SET_PMS_DASHBOARD_LOADING", false)
                    commit("SET_PMS_DASHBOARD_DATA", res.data)
                    return res
                }).catch((err) => {
                    commit("SET_PMS_DASHBOARD_LOADING", false)
                    showErrors(err)
                })
        }
    },
    mutations: {
        LOGOUT: (state) => {
            state.user.access_token = null;
            state.user.data = {};
            state.user.permissions = [];
            state.profile.data = {};
            localStorage.removeItem("access_token");
            localStorage.removeItem("permissions")
        },
        SET_TOKEN: (state, token) => {
            state.user.access_token = token;
            localStorage.setItem('access_token', token);
        },
        SET_PROFILE: (state, data) => {
            state.profile.data = data
        },
        SET_USER_PERMISSIONS: (state, permissions) => {
            state.user.permissions = permissions
            localStorage.setItem('permissions', permissions)
        },
        SET_DASHBOARD_LOADING: (state, loading) => {
            state.dashboard.loading = loading
        },
        SET_DASHBOARD_DATA: (state, data) => {
            state.dashboard.data = data
        },
        SET_RESTAURANT_DASHBOARD_LOADING: (state, loading) => {
            state.restaurantDashboard.loading = loading
        },
        SET_RESTAURANT_DASHBOARD_DATA: (state, data) => {
            state.restaurantDashboard.data = data
        },
        SET_PMS_DASHBOARD_LOADING: (state, loading) => {
            state.pmsDashboard.loading = loading
        },
        SET_PMS_DASHBOARD_DATA: (state, data) => {
            state.pmsDashboard.data = data
        }
    },
    modules: {},
});

// dynamically import modules and add them to the store
const files = require.context('@/store/modules', true, /\.js$/)
files.keys().forEach(key => {
    const moduleName = (key.match(/\.\/(.+)\.js$/)[1]).split('/').pop()
    const moduleConfig = files(key).default
    store.registerModule(camelCase(moduleName) + 'Module', moduleConfig)
})

export default store;
