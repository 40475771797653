import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const roomCategoryUrl = 'pms/roomCategory'

export default {
    namespaced: true,
    state: {
        roomCategories: {
            data: [],
            loading: false
        },
        roomCategory: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getRoomCategories({state, commit}) {
            if (!state.roomCategories.data.length) {
                commit('SET_ROOM_CATEGORIES_LOADING', true)
                return axiosAdmin.get(`${roomCategoryUrl}`)
                    .then((res) => {
                        commit("SET_ROOM_CATEGORIES_LOADING", false);
                        commit('SET_ROOM_CATEGORIES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_ROOM_CATEGORIES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeRoomCategory({commit}, form) {
            return axiosAdmin.post(`${roomCategoryUrl}`, form)
                .then((res) => {
                    commit('ADD_ROOM_CATEGORY', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getRoomCategory({commit}, id) {
            commit('SET_ROOM_CATEGORY_LOADING', true)
            return axiosAdmin.get(`${roomCategoryUrl}/${id}`)
                .then((res) => {
                    commit('SET_ROOM_CATEGORY_LOADING', false)
                    commit('SET_ROOM_CATEGORY', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_ROOM_CATEGORY_LOADING', false)
                    showErrors(err)
                });
        },
        updateRoomCategory({commit}, {id, form}) {
            return axiosAdmin.put(`${roomCategoryUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_ROOM_CATEGORY', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteRoomCategory({commit}, id) {
            return axiosAdmin.delete(`${roomCategoryUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_ROOM_CATEGORY', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ROOM_CATEGORIES_LOADING: (state, loading) => {
            state.roomCategories.loading = loading
        },
        SET_ROOM_CATEGORIES: (state, data) => {
            state.roomCategories.data = data
        },
        ADD_ROOM_CATEGORY: (state, data) => {
            state.roomCategories.data.push(data)
        },
        SET_ROOM_CATEGORY_LOADING: (state, loading) => {
            state.roomCategory.loading = loading
        },
        SET_ROOM_CATEGORY: (state, data) => {
            state.roomCategory.data = data
        },
        UPDATE_ROOM_CATEGORY: (state, {id, data}) => {
            state.roomCategories.data[state.roomCategories.data.findIndex(roomCategory => roomCategory.id === id)] = data
        },
        REMOVE_ROOM_CATEGORY: (state, id) => {
            state.roomCategories.data = state.roomCategories.data.filter(data => data.id !== id)
        }
    }
}
