import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const bookingSourceUrl = 'pms/bookingSource'

export default {
    namespaced: true,
    state: {
        bookingSources: {
            data: [],
            loading: false
        },
        bookingSource: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getBookingSources({state, commit}) {
            if (!state.bookingSources.data.length) {
                commit('SET_BOOKING_SOURCES_LOADING', true)
                return axiosAdmin.get(`${bookingSourceUrl}`)
                    .then((res) => {
                        commit("SET_BOOKING_SOURCES_LOADING", false);
                        commit('SET_BOOKING_SOURCES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_BOOKING_SOURCES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeBookingSource({commit}, form) {
            return axiosAdmin.post(`${bookingSourceUrl}`, form)
                .then((res) => {
                    commit('ADD_BOOKING_SOURCE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getBookingSource({commit}, id) {
            commit('SET_BOOKING_SOURCE_LOADING', true)
            return axiosAdmin.get(`${bookingSourceUrl}/${id}`)
                .then((res) => {
                    commit('SET_BOOKING_SOURCE_LOADING', false)
                    commit('SET_BOOKING_SOURCE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_BOOKING_SOURCE_LOADING', false)
                    showErrors(err)
                });
        },
        updateBookingSource({commit}, {id, form}) {
            return axiosAdmin.put(`${bookingSourceUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_BOOKING_SOURCE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteBookingSource({commit}, id) {
            return axiosAdmin.delete(`${bookingSourceUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_BOOKING_SOURCE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_BOOKING_SOURCES_LOADING: (state, loading) => {
            state.bookingSources.loading = loading
        },
        SET_BOOKING_SOURCES: (state, data) => {
            state.bookingSources.data = data
        },
        ADD_BOOKING_SOURCE: (state, data) => {
            state.bookingSources.data.push(data)
        },
        SET_BOOKING_SOURCE_LOADING: (state, loading) => {
            state.bookingSource.loading = loading
        },
        SET_BOOKING_SOURCE: (state, data) => {
            state.bookingSource.data = data
        },
        UPDATE_BOOKING_SOURCE: (state, {id, data}) => {
            state.bookingSources.data[state.bookingSources.data.findIndex(bookingSource => bookingSource.id === id)] = data
        },
        REMOVE_BOOKING_SOURCE: (state, id) => {
            state.bookingSources.data = state.bookingSources.data.filter(data => data.id !== id)
        }
    }
}
