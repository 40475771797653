import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const inventoryReportUrl = 'inventory/report'

export default {
    namespaced: true,
    state: {
        categoryStocks: {
            data: [],
            loading: false
        },
        productStocks: {
            data: [],
            loading: false
        },
        productLedgers: {
            opening_stock:{},
            data: [],
            loading: false
        },
        productWisePurchases:{
            loading:false,
            data:[]
        },
        purchaseSummary:{
            loading:false,
            data:[]
        },
        productWiseSales:{
            loading:false,
            data:[]
        },
        salesSummary:{
            loading:false,
            data:[]
        },
    },
    getters: {},
    actions: {
        getCategoryStockSummary({commit}, {from_date = '', to_date = '', warehouse_id = ''}) {
            commit('SET_CATEGORY_STOCKS_LOADING', true)
            return axiosAdmin.get(`${inventoryReportUrl}/stock-summary?from_date=${from_date}&to_date=${to_date}&warehouse_id=${warehouse_id}`)
                .then((res) => {
                    commit("SET_CATEGORY_STOCKS_LOADING", false);
                    commit('SET_CATEGORY_STOCKS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_CATEGORY_STOCKS_LOADING", false);
                    showErrors(err)
                });
        },
        getProductStockSummary({commit}, {product_category_id, from_date = '', to_date = '', warehouse_id = ''}) {
            commit('SET_PRODUCT_STOCKS_LOADING', true)
            return axiosAdmin.get(`${inventoryReportUrl}/stock-summary/${product_category_id}?from_date=${from_date}&to_date=${to_date}&warehouse_id=${warehouse_id}`)
                .then((res) => {
                    commit("SET_PRODUCT_STOCKS_LOADING", false);
                    commit('SET_PRODUCT_STOCKS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PRODUCT_STOCKS_LOADING", false);
                    showErrors(err)
                });
        },
        getProductLedger({commit}, {product_id, from_date = '', to_date = ''}) {
            commit('SET_PRODUCT_LEDGER_LOADING', true)
            return axiosAdmin.get(`${inventoryReportUrl}/product-ledger/${product_id}?from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_PRODUCT_LEDGER_LOADING", false);
                    commit('SET_PRODUCT_LEDGERS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PRODUCT_LEDGER_LOADING", false);
                    showErrors(err)
                });
        },
        getProductWisePurchases({commit}, {from_date = '', to_date = '', product_id = ''}) {
            commit('SET_PRODUCT_WISE_PURCHASES_LOADING', true)
            return axiosAdmin.get(`${inventoryReportUrl}/purchase/product-wise?from_date=${from_date}&to_date=${to_date}&product_id=${product_id}`)
                .then((res) => {
                    commit("SET_PRODUCT_WISE_PURCHASES_LOADING", false);
                    commit('SET_PRODUCT_WISE_PURCHASES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PRODUCT_WISE_PURCHASES_LOADING", false);
                    showErrors(err)
                });
        },
        getPurchaseSummary({commit}, {from_date = '', to_date = '', supplier_ledger_id = ''}) {
            commit('SET_PURCHASE_SUMMARY_LOADING', true)
            return axiosAdmin.get(`${inventoryReportUrl}/purchase/summary?from_date=${from_date}&to_date=${to_date}&supplier_ledger_id=${supplier_ledger_id}`)
                .then((res) => {
                    commit("SET_PURCHASE_SUMMARY_LOADING", false);
                    commit('SET_PURCHASE_SUMMARY', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PURCHASE_SUMMARY_LOADING", false);
                    showErrors(err)
                });
        },
        getProductWiseSales({commit}, {from_date = '', to_date = '', product_id = ''}) {
            commit('SET_PRODUCT_WISE_SALES_LOADING', true)
            return axiosAdmin.get(`${inventoryReportUrl}/sale/product-wise?from_date=${from_date}&to_date=${to_date}&product_id=${product_id}`)
                .then((res) => {
                    commit("SET_PRODUCT_WISE_SALES_LOADING", false);
                    commit('SET_PRODUCT_WISE_SALES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PRODUCT_WISE_SALES_LOADING", false);
                    showErrors(err)
                });
        },
        getSalesSummary({commit}, {from_date = '', to_date = '', client_ledger_id = ''}) {
            commit('SET_SALES_SUMMARY_LOADING', true)
            return axiosAdmin.get(`${inventoryReportUrl}/sale/summary?from_date=${from_date}&to_date=${to_date}&client_ledger_id=${client_ledger_id}`)
                .then((res) => {
                    commit("SET_SALES_SUMMARY_LOADING", false);
                    commit('SET_SALES_SUMMARY', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_SALES_SUMMARY_LOADING", false);
                    showErrors(err)
                });
        },
    },
    mutations: {
        SET_CATEGORY_STOCKS_LOADING: (state, loading) => {
            state.categoryStocks.loading = loading
        },
        SET_CATEGORY_STOCKS: (state, data) => {
            state.categoryStocks.data = data
        },
        SET_PRODUCT_STOCKS_LOADING: (state, loading) => {
            state.productStocks.loading = loading
        },
        SET_PRODUCT_STOCKS: (state, data) => {
            state.productStocks.data = data
        },
        SET_PRODUCT_LEDGER_LOADING: (state, loading) => {
            state.productLedgers.loading = loading
        },
        SET_PRODUCT_LEDGERS: (state, data) => {
            state.productLedgers.opening_stock=data.opening_stock
            state.productLedgers.data = data.data
        },
        SET_PRODUCT_WISE_PURCHASES_LOADING: (state, loading) => {
            state.productWisePurchases.loading = loading
        },
        SET_PRODUCT_WISE_PURCHASES: (state, data) => {
            state.productWisePurchases.data = data
        },
        SET_PURCHASE_SUMMARY_LOADING: (state, loading) => {
            state.purchaseSummary.loading = loading
        },
        SET_PURCHASE_SUMMARY: (state, data) => {
            state.purchaseSummary.data = data
        },
        SET_PRODUCT_WISE_SALES_LOADING: (state, loading) => {
            state.productWiseSales.loading = loading
        },
        SET_PRODUCT_WISE_SALES: (state, data) => {
            state.productWiseSales.data = data
        },
        SET_SALES_SUMMARY_LOADING: (state, loading) => {
            state.salesSummary.loading = loading
        },
        SET_SALES_SUMMARY: (state, data) => {
            state.salesSummary.data = data
        },
    }
}
