import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const roomAmenityUrl = 'pms/roomAmenity'

export default {
    namespaced: true,
    state: {
        roomAmenities: {
            data: [],
            loading: false
        },
        roomAmenity: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getRoomAmenities({state, commit}) {
            if (!state.roomAmenities.data.length) {
                commit('SET_ROOM_AMENITIES_LOADING', true)
                return axiosAdmin.get(`${roomAmenityUrl}`)
                    .then((res) => {
                        commit("SET_ROOM_AMENITIES_LOADING", false);
                        commit('SET_ROOM_AMENITIES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_ROOM_AMENITIES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeRoomAmenity({commit}, form) {
            return axiosAdmin.post(`${roomAmenityUrl}`, form)
                .then((res) => {
                    commit('ADD_ROOM_AMENITY', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getRoomAmenity({commit}, id) {
            commit('SET_ROOM_AMENITY_LOADING', true)
            return axiosAdmin.get(`${roomAmenityUrl}/${id}`)
                .then((res) => {
                    commit('SET_ROOM_AMENITY_LOADING', false)
                    commit('SET_ROOM_AMENITY', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_ROOM_AMENITY_LOADING', false)
                    showErrors(err)
                });
        },
        updateRoomAmenity({commit}, {id, form}) {
            return axiosAdmin.put(`${roomAmenityUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_ROOM_AMENITY', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteRoomAmenity({commit}, id) {
            return axiosAdmin.delete(`${roomAmenityUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_ROOM_AMENITY', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ROOM_AMENITIES_LOADING: (state, loading) => {
            state.roomAmenities.loading = loading
        },
        SET_ROOM_AMENITIES: (state, data) => {
            state.roomAmenities.data = data
        },
        ADD_ROOM_AMENITY: (state, data) => {
            state.roomAmenities.data.push(data)
        },
        SET_ROOM_AMENITY_LOADING: (state, loading) => {
            state.roomAmenity.loading = loading
        },
        SET_ROOM_AMENITY: (state, data) => {
            state.roomAmenity.data = data
        },
        UPDATE_ROOM_AMENITY: (state, {id, data}) => {
            state.roomAmenities.data[state.roomAmenities.data.findIndex(roomAmenity => roomAmenity.id === id)] = data
        },
        REMOVE_ROOM_AMENITY: (state, id) => {
            state.roomAmenities.data = state.roomAmenities.data.filter(data => data.id !== id)
        }
    }
}
