import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const paymentVoucherUrl = 'account/paymentVoucher'

export default {
    namespaced: true,
    state: {
        paymentVouchers: {
            data: [],
            loading: false
        },
        paymentVoucher: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getPaymentVoucherCode(){
            return axiosAdmin.get(`${paymentVoucherUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getPaymentVouchers({state, commit}) {
            if (!state.paymentVouchers.data.length) {
                commit('SET_PAYMENT_VOUCHERS_LOADING', true)
                return axiosAdmin.get(`${paymentVoucherUrl}`)
                    .then((res) => {
                        commit("SET_PAYMENT_VOUCHERS_LOADING", false);
                        commit('SET_PAYMENT_VOUCHERS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_PAYMENT_VOUCHERS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storePaymentVoucher({commit}, form) {
            return axiosAdmin.post(`${paymentVoucherUrl}`, form)
                .then((res) => {
                    commit('ADD_PAYMENT_VOUCHER', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getPaymentVoucher({commit}, id) {
            commit('SET_PAYMENT_VOUCHER_LOADING', true)
            return axiosAdmin.get(`${paymentVoucherUrl}/${id}`)
                .then((res) => {
                    commit('SET_PAYMENT_VOUCHER_LOADING', false)
                    commit('SET_PAYMENT_VOUCHER', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_PAYMENT_VOUCHER_LOADING', false)
                    showErrors(err)
                });
        },

        deletePaymentVoucher({commit}, {id,cancelled_reason}) {
            return axiosAdmin.delete(`${paymentVoucherUrl}/${id}?cancelled_reason=${cancelled_reason}`)
                .then((res) => {
                    commit('REMOVE_PAYMENT_VOUCHER', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_PAYMENT_VOUCHERS_LOADING: (state, loading) => {
            state.paymentVouchers.loading = loading
        },
        SET_PAYMENT_VOUCHERS: (state, data) => {
            state.paymentVouchers.data = data
        },
        ADD_PAYMENT_VOUCHER: (state, data) => {
            state.paymentVouchers.data.push(data)
        },
        SET_PAYMENT_VOUCHER_LOADING: (state, loading) => {
            state.paymentVoucher.loading = loading
        },
        SET_PAYMENT_VOUCHER: (state, data) => {
            state.paymentVoucher.data = data
        },

        REMOVE_PAYMENT_VOUCHER: (state, id) => {
            state.paymentVouchers.data = state.paymentVouchers.data.filter(data => data.id !== id)
        }
    }
}
