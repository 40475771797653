import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const restaurantTableUrl = 'restaurant/restaurantTable'

export default {
    namespaced: true,
    state: {
        restaurantTables: {
            data: [],
            loading: false
        },
        restaurantTable: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getRestaurantTables({commit}) {
            commit('SET_RESTAURANT_TABLES_LOADING', true)
            return axiosAdmin.get(`${restaurantTableUrl}`)
                .then((res) => {
                    commit("SET_RESTAURANT_TABLES_LOADING", false);
                    commit('SET_RESTAURANT_TABLES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_RESTAURANT_TABLES_LOADING", false);
                    showErrors(err)
                });
        },
        storeRestaurantTable({commit}, form) {
            return axiosAdmin.post(`${restaurantTableUrl}`, form)
                .then((res) => {
                    commit('ADD_RESTAURANT_TABLE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getRestaurantTable({commit}, id) {
            commit('SET_RESTAURANT_TABLE_LOADING', true)
            return axiosAdmin.get(`${restaurantTableUrl}/${id}`)
                .then((res) => {
                    commit('SET_RESTAURANT_TABLE_LOADING', false)
                    commit('SET_RESTAURANT_TABLE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_RESTAURANT_TABLE_LOADING', false)
                    showErrors(err)
                });
        },
        updateRestaurantTable({commit}, {id, form}) {
            return axiosAdmin.put(`${restaurantTableUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_RESTAURANT_TABLE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteRestaurantTable({commit}, id) {
            return axiosAdmin.delete(`${restaurantTableUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_RESTAURANT_TABLE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_RESTAURANT_TABLES_LOADING: (state, loading) => {
            state.restaurantTables.loading = loading
        },
        SET_RESTAURANT_TABLES: (state, data) => {
            state.restaurantTables.data = data
        },
        ADD_RESTAURANT_TABLE: (state, data) => {
            state.restaurantTables.data.push(data)
        },
        SET_RESTAURANT_TABLE_LOADING: (state, loading) => {
            state.restaurantTable.loading = loading
        },
        SET_RESTAURANT_TABLE: (state, data) => {
            state.restaurantTable.data = data
        },
        UPDATE_RESTAURANT_TABLE: (state, {id, data}) => {
            state.restaurantTables.data[state.restaurantTables.data.findIndex(restaurantTable => restaurantTable.id === id)] = data
        },
        REMOVE_RESTAURANT_TABLE: (state, id) => {
            state.restaurantTables.data = state.restaurantTables.data.filter(data => data.id !== id)
        }
    }
}
