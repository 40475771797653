import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const foodItemUrl = 'restaurant/foodItem'

export default {
    namespaced: true,
    state: {
        foodItems: {
            data: [],
            loading: false
        },
        foodItem: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getFoodItems({commit}) {
            commit('SET_FOOD_ITEMS_LOADING', true)
            return axiosAdmin.get(`${foodItemUrl}`)
                .then((res) => {
                    commit("SET_FOOD_ITEMS_LOADING", false);
                    commit('SET_FOOD_ITEMS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_FOOD_ITEMS_LOADING", false);
                    showErrors(err)
                });
        },
        storeFoodItem({commit}, form) {
            return axiosAdmin.post(`${foodItemUrl}`, form)
                .then((res) => {
                    commit('ADD_FOOD_ITEM', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        updateStatus({commit}, id) {
            return axiosAdmin.put(`${foodItemUrl}/${id}/updateFoodItemStatus`)
                .then((res) => {
                    const status = res.data.status
                    commit('SET_STATUS', {id, status})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getFoodItem({commit}, id) {
            commit('SET_FOOD_ITEM_LOADING', true)
            return axiosAdmin.get(`${foodItemUrl}/${id}`)
                .then((res) => {
                    commit('SET_FOOD_ITEM_LOADING', false)
                    commit('SET_FOOD_ITEM', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_FOOD_ITEM_LOADING', false)
                    showErrors(err)
                });
        },
        updateFoodItem({commit}, {id, form}) {
            return axiosAdmin.post(`${foodItemUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_FOOD_ITEM', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteFoodItem({commit}, id) {
            return axiosAdmin.delete(`${foodItemUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_FOOD_ITEM', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_FOOD_ITEMS_LOADING: (state, loading) => {
            state.foodItems.loading = loading
        },
        SET_FOOD_ITEMS: (state, data) => {
            state.foodItems.data = data
        },
        ADD_FOOD_ITEM: (state, data) => {
            state.foodItems.data.push(data)
        },
        SET_FOOD_ITEM_LOADING: (state, loading) => {
            state.foodItem.loading = loading
        },
        SET_FOOD_ITEM: (state, data) => {
            state.foodItem.data = data
        },
        UPDATE_FOOD_ITEM: (state, {id, data}) => {
            state.foodItems.data[state.foodItems.data.findIndex(foodItem => foodItem.id === id)] = data
        },
        SET_STATUS: (state, {id, status}) => {
            const index = state.foodItems.data.findIndex(foodItem => foodItem.id === id)
            state.foodItems.data[index].status = status
        },
        REMOVE_FOOD_ITEM: (state, id) => {
            state.foodItems.data = state.foodItems.data.filter(data => data.id !== id)
        }
    }
}
