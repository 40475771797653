import {axiosAdmin} from '@/helpers/axios';
import showErrors from "@/helpers/showErrors";

export default {
    namespaced: true,
    state: {
        users: {
            data: [],
            loading: false,
        },
        user: {
            data: {},
            loading: false
        }
    },
    actions: {
        getUsers({state, commit}) {
            if (!state.users.data.length) {
                commit('SET_USERS_LOADING', true)
                return axiosAdmin.get(`user`)
                    .then((res) => {
                        commit("SET_USERS_LOADING", false);
                        commit('SET_USERS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_USERS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeUser({commit}, form) {
            return axiosAdmin.post(`user`, form)
                .then((res) => {
                    commit('ADD_USER', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        updateUser({commit}, {id, form}) {
            return axiosAdmin.post(`user/${id}`, form)
                .then((res) => {
                    commit('UPDATE_USER', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        updateStatus({commit}, id) {
            return axiosAdmin.put(`user/${id}/updateUserStatus`)
                .then((res) => {
                    const status = res.data.status
                    commit('SET_STATUS', {id, status})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getUser({commit}, id) {
            commit('SET_USER_LOADING', true)
            return axiosAdmin.get(`user/${id}`)
                .then((res) => {
                    commit('SET_USER_LOADING', false)
                    commit('SET_USER', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_USER_LOADING', false)
                    showErrors(err)
                });
        },
        deleteUser({commit}, id) {
            return axiosAdmin.delete(`user/${id}`)
                .then((res) => {
                    commit('REMOVE_USER', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    getters: {},
    mutations: {
        SET_USERS_LOADING: (state, loading) => {
            state.users.loading = loading
        },
        SET_USERS: (state, data) => {
            state.users.data = data
        },
        ADD_USER: (state, data) => {
            state.users.data.push(data)
        },
        SET_USER_LOADING: (state, loading) => {
            state.user.loading = loading
        },
        SET_USER: (state, data) => {
            state.user.data = data
        },
        UPDATE_USER: (state, {id, data}) => {
            state.users.data[state.users.data.findIndex(user => user.id === id)] = data
        },
        SET_STATUS: (state, {id, status}) => {
            const index = state.users.data.findIndex(user => user.id === id)
            state.users.data[index].status = status
        },
        REMOVE_USER: (state, id) => {
            state.users.data = state.users.data.filter(data => data.id !== id)
        }
    }
}
