import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const bookingTypeUrl = 'pms/bookingType'

export default {
    namespaced: true,
    state: {
        bookingTypes: {
            data: [],
            loading: false
        },
        bookingType: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getBookingTypes({state, commit}) {
            if (!state.bookingTypes.data.length) {
                commit('SET_BOOKING_TYPES_LOADING', true)
                return axiosAdmin.get(`${bookingTypeUrl}`)
                    .then((res) => {
                        commit("SET_BOOKING_TYPES_LOADING", false);
                        commit('SET_BOOKING_TYPES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_BOOKING_TYPES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeBookingType({commit}, form) {
            return axiosAdmin.post(`${bookingTypeUrl}`, form)
                .then((res) => {
                    commit('ADD_BOOKING_TYPE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getBookingType({commit}, id) {
            commit('SET_BOOKING_TYPE_LOADING', true)
            return axiosAdmin.get(`${bookingTypeUrl}/${id}`)
                .then((res) => {
                    commit('SET_BOOKING_TYPE_LOADING', false)
                    commit('SET_BOOKING_TYPE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_BOOKING_TYPE_LOADING', false)
                    showErrors(err)
                });
        },
        updateBookingType({commit}, {id, form}) {
            return axiosAdmin.put(`${bookingTypeUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_BOOKING_TYPE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteBookingType({commit}, id) {
            return axiosAdmin.delete(`${bookingTypeUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_BOOKING_TYPE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_BOOKING_TYPES_LOADING: (state, loading) => {
            state.bookingTypes.loading = loading
        },
        SET_BOOKING_TYPES: (state, data) => {
            state.bookingTypes.data = data
        },
        ADD_BOOKING_TYPE: (state, data) => {
            state.bookingTypes.data.push(data)
        },
        SET_BOOKING_TYPE_LOADING: (state, loading) => {
            state.bookingType.loading = loading
        },
        SET_BOOKING_TYPE: (state, data) => {
            state.bookingType.data = data
        },
        UPDATE_BOOKING_TYPE: (state, {id, data}) => {
            state.bookingTypes.data[state.bookingTypes.data.findIndex(bookingType => bookingType.id === id)] = data
        },
        REMOVE_BOOKING_TYPE: (state, id) => {
            state.bookingTypes.data = state.bookingTypes.data.filter(data => data.id !== id)
        }
    }
}
