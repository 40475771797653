import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const productCategoryUrl = 'inventory/productCategory'

export default {
    namespaced: true,
    state: {
        productCategories: {
            data: [],
            loading: false
        },
        productCategory: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getProductCategoryCode(){
            return axiosAdmin.get(`${productCategoryUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getProductCategories({commit}) {
            commit('SET_PRODUCT_CATEGORIES_LOADING', true)
            return axiosAdmin.get(`${productCategoryUrl}`)
                .then((res) => {
                    commit("SET_PRODUCT_CATEGORIES_LOADING", false);
                    commit('SET_PRODUCT_CATEGORIES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PRODUCT_CATEGORIES_LOADING", false);
                    showErrors(err)
                });
        },
        storeProductCategory({dispatch}, form) {
            return axiosAdmin.post(`${productCategoryUrl}`, form)
                .then((res) => {
                    dispatch('getProductCategories')
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getProductCategory({commit}, id) {
            commit('SET_PRODUCT_CATEGORY_LOADING', true)
            return axiosAdmin.get(`${productCategoryUrl}/${id}`)
                .then((res) => {
                    commit('SET_PRODUCT_CATEGORY_LOADING', false)
                    commit('SET_PRODUCT_CATEGORY', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_PRODUCT_CATEGORY_LOADING', false)
                    showErrors(err)
                });
        },
        updateProductCategory({dispatch}, {id, form}) {
            return axiosAdmin.post(`${productCategoryUrl}/${id}`, form)
                .then((res) => {
                    dispatch('getProductCategories')
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteProductCategory({dispatch}, id) {
            return axiosAdmin.delete(`${productCategoryUrl}/${id}`)
                .then((res) => {
                    dispatch('getProductCategories')
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_PRODUCT_CATEGORIES_LOADING: (state, loading) => {
            state.productCategories.loading = loading
        },
        SET_PRODUCT_CATEGORIES: (state, data) => {
            state.productCategories.data = data
        },
        SET_PRODUCT_CATEGORY_LOADING: (state, loading) => {
            state.productCategory.loading = loading
        },
        SET_PRODUCT_CATEGORY: (state, data) => {
            state.productCategory.data = data
        }
    }
}
