import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const accountOpeningBalanceUrl = 'account/accountOpeningBalance'

export default {
    namespaced: true,
    state: {
        accountOpeningBalances: {
            data: [],
            loading: false
        },
        accountOpeningBalance: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getAccountOpeningBalances({state, commit}) {
            if (!state.accountOpeningBalances.data.length) {
                commit('SET_ACCOUNT_OPENING_BALANCES_LOADING', true)
                return axiosAdmin.get(`${accountOpeningBalanceUrl}`)
                    .then((res) => {
                        commit("SET_ACCOUNT_OPENING_BALANCES_LOADING", false);
                        commit('SET_ACCOUNT_OPENING_BALANCES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_ACCOUNT_OPENING_BALANCES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeAccountOpeningBalance({commit}, form) {
            return axiosAdmin.post(`${accountOpeningBalanceUrl}`, form)
                .then((res) => {
                    commit('ADD_ACCOUNT_OPENING_BALANCE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getAccountOpeningBalance({commit}, id) {
            commit('SET_ACCOUNT_OPENING_BALANCE_LOADING', true)
            return axiosAdmin.get(`${accountOpeningBalanceUrl}/${id}`)
                .then((res) => {
                    commit('SET_ACCOUNT_OPENING_BALANCE_LOADING', false)
                    commit('SET_ACCOUNT_OPENING_BALANCE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_ACCOUNT_OPENING_BALANCE_LOADING', false)
                    showErrors(err)
                });
        },
        updateAccountOpeningBalance({commit}, {id, form}) {
            return axiosAdmin.put(`${accountOpeningBalanceUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_ACCOUNT_OPENING_BALANCE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteAccountOpeningBalance({commit}, id) {
            return axiosAdmin.delete(`${accountOpeningBalanceUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_ACCOUNT_OPENING_BALANCE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ACCOUNT_OPENING_BALANCES_LOADING: (state, loading) => {
            state.accountOpeningBalances.loading = loading
        },
        SET_ACCOUNT_OPENING_BALANCES: (state, data) => {
            state.accountOpeningBalances.data = data
        },
        ADD_ACCOUNT_OPENING_BALANCE: (state, data) => {
            state.accountOpeningBalances.data.push(data)
        },
        SET_ACCOUNT_OPENING_BALANCE_LOADING: (state, loading) => {
            state.accountOpeningBalance.loading = loading
        },
        SET_ACCOUNT_OPENING_BALANCE: (state, data) => {
            state.accountOpeningBalance.data = data
        },
        UPDATE_ACCOUNT_OPENING_BALANCE: (state, {id, data}) => {
            state.accountOpeningBalances.data[state.accountOpeningBalances.data.findIndex(accountOpeningBalance => accountOpeningBalance.id === id)] = data
        },
        REMOVE_ACCOUNT_OPENING_BALANCE: (state, id) => {
            state.accountOpeningBalances.data = state.accountOpeningBalances.data.filter(data => data.id !== id)
        }
    }
}
